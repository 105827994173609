export const getDefaultListDeparture = async lang => {
    const fetch = await getDeparture(lang, true); // запрашивает дефолтный список по строке
    const response = await fetch.json(); // парсит json
    const listDefault = true // флаг о том, что этот список дефолтный
    const listSort = response // записываем результат

    return {listDefault, listSort}
}

export const getListDeparture = async (lang, str) => {
    let listDefault
    let listSort

    let fetch = await getDeparture(lang,false, str); // запрашивает список по строке
    let response = await fetch.json(); // парсит json

    if(response.length === 0){
        // пустой ответ
        response = null
    }

    if(response == null){
        // ничего не найдено
        const allList = await getDefaultListDeparture(lang)
        return allList
    } else {
        // есть ответ по поиску
        listDefault = false  // флаг о том, что этот список НЕдефолтный
        // listSort = sortByKey(response, 'name', str); // сортирует список городов по ключу
        listSort = response; // сортирует список городов по ключу
        return {listDefault, listSort}
    }
}


const DEPARTURE_POINTS_URL = process.env.VUE_APP_BASEURL + process.env.VUE_APP_DEPARTURE_POINTS_URL;
export const getDeparture = (language = 'en', all = 'true', search) => {
    let url = new URL(DEPARTURE_POINTS_URL);
    Object.entries({
        language,
        all,
        search
    }).forEach(([key, value]) => {
        if(value != null){
            url.searchParams.append(key, value)
        }
    });
    return fetch(url);
}