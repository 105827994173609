<template>
  <div class="card-tel__profile">
    <div class="card-tel__flag">
      <base-flag-of-the-country :country="country"/>
    </div>
    <div class="card-tel__content">
      {{ formattedTel }}
    </div>
  </div>
</template>

<script>
import BaseFlagOfTheCountry from "@/shared/ui/BaseFlagOfTheCountry";
import {formattedPhone} from '@/shared/libs/formattedPhone'
export default {
  name: "CardContact",
  components: {BaseFlagOfTheCountry},
  props:{
    tel: {
      type: String,
    },
    country:{
      type: String,
      validator(value){
        return ['ru', 'en', 'cn', 'kg'].includes(value)
      }
    }
  },
  computed:{
    formattedTel(){
      return formattedPhone(this.tel)
    }
  },
}
</script>

<style>
.card-tel__profile{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
}

.card-tel__flag{
  margin-right: 10px;
}

.card-tel__content{
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #141414;
  flex: 1;
}

@media screen and (min-width: 768px){
  .card-tel__content{
    color: #EE1B2C;
    font-size: 14px;
  }
}

.card-tel__icon{
  margin-left: 10px;
}

@media screen and (min-width: 768px){
  .card-tel__icon{
    display: none;
  }
}
</style>