<template>
<base-form>
  <template v-slot:title>
    <h2 class="base-h2"> {{ vocabulary.h_calculate || 'Калькулятор' }} </h2>
  </template>
  <template v-slot:content>
    <calculate-cargo/>
  </template>
</base-form>
</template>

<script>
import BaseForm from "@/shared/ui/BaseForm";
import CalculateCargo from "@/features/calculate-cargo/CalculateCargo";
import {mapGetters} from "vuex";
export default {
  name: "WidgetCalculate",
  components: {CalculateCargo, BaseForm},
  computed:{
    ...mapGetters(['getVocabulary']),
    vocabulary(){
      return this.getVocabulary
    }
  },
}
</script>

<style scoped>

</style>