export const formattedPhone = tel =>{
    const formatted = tel.split('');
    if(tel.length == 12){
        formatted.splice(2,"", " (");
        formatted.splice(6,"", ") ");
        formatted.splice(10,"", "-");
        formatted.splice(13,"", "-");
    } else if(tel.length == 13){
        if(formatted[1] !== 7){
            // если код страны не +7 (сделано сейчас под киргизию +999 999 999 999)
            formatted.splice(4,"", " ");
            formatted.splice(8,"", " ");
            formatted.splice(12,"", " ");
        } else {
            formatted.splice(3,"", " (");
            formatted.splice(7,"", ") ");
            formatted.splice(11,"", "-");
            formatted.splice(14,"", "-");
        }
    } else if(tel.length == 11){
        formatted.splice(0,1, '+7');
        formatted.splice(1,"", " (");
        formatted.splice(5,"", ") ");
        formatted.splice(9,"", "-");
        formatted.splice(12,"", "-");
    } else {
        return tel
    }
    return formatted.join('')
}