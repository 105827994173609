<template>
<div
    class="base-driver"
    :class="{
        'base-driver--fill': fillScreen,
        'base-driver--full': fullScreen,
      }"
>
  <div class="base-driver__close"><slot name="cross"></slot></div>
  <div class="base-driver__headline"><slot name="headline"></slot></div>
  <div class="base-driver__content"><slot name="body"></slot></div>
</div>
</template>

<script>
export default {
  name: "BaseDriver",
  props:{
    fullScreen:{
      type: Boolean,
      default(){
        return false
      }
    },
    fillScreen:{
      type: Boolean,
      default(){
        return false
      }
    },
  }
}
</script>

<style>
.base-driver{
  padding: 20px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(7px);
  border-radius: 24px 24px 0 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.base-driver--full{
  height: 80vh;
}
.base-driver--fill{
  background: #F9F9F9;
}

.base-driver__title{
  margin-bottom: 20px;
}

.base-driver__close{
  position: absolute;
  top: 20px;
  right: 20px;
}

.base-driver__content{
  height: 100%;
  overflow: auto;
}
</style>