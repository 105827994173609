<template>
  <selector-point
      :set-selected-point="getPointDestination"
      :type-point="vocabulary.label_destination + ':' || 'Куда:'"
      :func-get-list="funcGetListDestination"
      @selectedPoint="handleSelectedDestination"
  />
</template>

<script>
import SelectorPoint from "@/entities/cargo/ui/SelectorPoint";
import {mapGetters} from "vuex";
import {getListDestination} from "@/entities/cargo/api/getListDestination";
export default {
  name: "SelectorDestination",
  components: {SelectorPoint},
  emits:['selectedDestination'],
  computed:{
    ...mapGetters(['getVocabulary', 'getDestination']),
    vocabulary(){
      return this.getVocabulary
    },
    getPointDestination(){
      return this.getDestination
    }
  },
  data(){
    return{
      funcGetListDestination: getListDestination,
    }
  },
  methods:{
    handleSelectedDestination: function (obj){
      this.$emit('selectedDestination', obj)
    }
  }
}
</script>