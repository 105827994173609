<template>
  <main-loader v-if="isActiveLoader"/>
  <main-header/>
  <main-body/>
</template>

<script>
import MainHeader from "@/templates/MainHeader";
import {mapMutations} from "vuex"
import MainLoader from "@/widgets/loader/MainLoader";
import MainBody from "@/templates/MainBody";
export default {
  name: 'App',
  components: {
    MainBody,
    MainLoader,
    MainHeader,
  },
  data(){
    return{
      isActiveLoader: true
    }
  },

  mounted() {
    this.assignUID() // Присваевает user уникальный номер
    setTimeout(() => {
      this.isActiveLoader = false
    }, 1000) // Таймер на показ лоадера на старте
  },
  methods:{
    ...mapMutations(['updateUID']),

    // Присваевает user уникальный номер
    assignUID: function (){
      let uid;
      const savedUID = localStorage.getItem('uid')
      savedUID != null ? uid = JSON.parse(savedUID) : uid = Date.now()
      this.updateUID(uid)
      localStorage.setItem('uid', JSON.stringify(uid))
    }
  }
}
</script>


<style>
@font-face {
  font-family: 'Montserrat';
  src: url('./shared/assets/fonts/Montserrat-Regular.ttf') format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./shared/assets/fonts/Montserrat-Italic.ttf') format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./shared/assets/fonts/Montserrat-Medium.ttf') format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./shared/assets/fonts/Montserrat-SemiBold.ttf') format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./shared/assets/fonts/Montserrat-Bold.ttf') format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./shared/assets/fonts/Montserrat-ExtraBold.ttf') format("truetype");
  font-weight: 800;
  font-style: normal;
}

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a, label, input{
  -webkit-tap-highlight-color: transparent;
}


body{
  font-family: 'Montserrat';
  -webkit-overflow-scrolling: touch;
  -webkit-text-size-adjust: none;
  overscroll-behavior: none;
  background: #F8F8F8 url("./shared/assets/bg/background.svg") no-repeat -560px top / cover;
  min-height: 100vh;
  /*overflow-y: hidden;*/
}

.body--hidden{
  height: 100vh;
  overflow: hidden;
}

.base-description{
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #A2A2A2;
}

.base-h1{
  font-weight: 800;
  font-size: 20px;
  line-height: 130%;
  text-align: center;
  color: #141414;
}
.base-h1--left{
  text-align: left;
}
@media screen and (min-width: 768px){
  .base-h1{
    font-size: 22px;
    text-align: left;
  }
}

.base-h2{
  font-weight: 800;
  font-size: 20px;
  line-height: 130%;
  text-align: center;
  color: #141414;
}
.base-h2--left{
  text-align: left;
}

@media screen and (min-width: 768px){
  .base-h2{
    font-size: 22px;
  }
}

.base-h3{
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #141414;
}

#app{
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.wrap{
  padding: 0;
  max-width: 840px;
  width: 100%;
  margin: 0 auto;
}

.main-content__title{
  padding: 0 20px;
  margin-top: 30px;
}

@media screen and (min-width: 768px){
  body{
    background-position: right -200px;
    position: relative;
  }

  .wrap{
    padding: 0 30px;
  }

  .main-content__title{
    padding: 0;
    margin-bottom: 80px;
    max-width: 405px;
  }

  .main-content{
    width: 100%;
    max-width: 405px;
  }


  .main-container{
    display: flex;
    overflow: hidden;
    position: relative;
    height: 100%;
    flex: 1;
    width: 100%;
  }
}
@media screen and (min-width: 1000px){
  .main-content__title{
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1200px){
  body{
    background-position: right 0;
    background-size: 100%;
  }
}

.paddingForGboard{
  padding-bottom: 200px;
}

.main-illustration{
  position: relative;
  z-index: -1;
  width: 645px;
  max-width: 100%;
  height: 300px;
  background: url("./shared/assets/illustration/roudmap.png") no-repeat center top / contain;
  overflow: visible;
}

.main-illustration > svg{
  position: relative;
  top: -130px;
  overflow: visible;
  left: 20px;
}

@media screen and (min-width: 768px){
  .main-illustration{
    width: 750px;
    height: 750px;
    position: absolute;
    bottom: 0;
    right: -190px;
    background-position: right bottom;
  }
  .main-illustration > svg{
    width: 353px;
    height: 276px;
    top: 100px;
    overflow: visible;
    left: 40px;
  }
}

@media screen and (min-width: 820px){
  .main-container{
    overflow: visible;
    /*padding-top: 50px;*/
    position: relative;
  }
  .main-illustration{
    width: 575px;
    height: 575px;
    bottom: 50px;
    right: -200px;
    background-position: right bottom;
    overflow: visible;
  }

  .main-illustration > svg{
    width: 271px;
    height: 211px;
    top: 50px;
    overflow: visible;
    left: 40px;
  }
}

.third-point{
  animation: 8s infinite normal third-point;
}
.second-point{
  animation: 8s infinite normal second-point;
}
.first-point{
  animation: 8s infinite normal first-point;
}


.skeleton{
  animation: fade 1s linear infinite alternate;
}

.skeleton__title{
  display: block;
  height: 17px;
  width: 150px;
}

.skeleton__title,
.skeleton__animation{
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1.5s loading linear infinite;
}

@keyframes Rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotate-right {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}


@keyframes fade {
  0% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes first-point{
  0%{
    transform: translateY(0);
  }
  25%{
    transform: translateY(-50px);
  }
  50%{
    transform: translateY(20px);
  }
  75%{
    transform: translateY(80px);
  }
}
@keyframes second-point{
  0%{
    transform: translateY(0);
  }
  25%{
    transform: translateY(-30px);
  }
  50%{
    transform: translateY(-10px);
  }
  75%{
    transform: translateY(-40px);
  }
}
@keyframes third-point{
  0%{
    transform: translateY(0);
  }
  25%{
    transform: translateY(-50px);
  }
  50%{
    transform: translateY(20px);
  }
  75%{
    transform: translateY(-30px);
  }
}
@keyframes loading {
  to {
    background-position-x: -200%;
  }
}
</style>

