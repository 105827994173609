<template>
  <!-- Скелетон -->
  <base-sausage
      v-if="isLoading"
      :is-loading="isLoading"
  />

  <!-- Ошибка -->
  <template v-else-if="isError">
    <base-warn :message="messageError" type="error"/>
  </template>

  <!-- Главный -->
  <base-sausage v-else>
    <template v-slot:first-cell-label>{{ vocabulary.h_cost || 'Стоимость' }}</template>
    <template v-slot:first-cell-value>{{ cargo.total }} $</template>

    <template v-slot:last-cell-label>{{ vocabulary.h_time || 'Сроки доставки' }}</template>
    <template v-slot:last-cell-value>{{ timePeriod }} {{ vocabulary.postfix_time }}</template>
  </base-sausage>
</template>

<script>
import BaseSausage from "@/entities/cargo/ui/BaseSausage";
import {mapGetters} from "vuex";
import BaseWarn from "@/shared/ui/BaseWarn";
import {normalizeStatus} from "@/shared/libs/validation";
export default {
  name: "SausageCargo",
  components: {BaseWarn, BaseSausage},
  computed:{
    ...mapGetters(['getVocabulary', 'statusLoadingCargo', 'getCargo', 'getCargoError']),
    vocabulary(){
      return this.getVocabulary
    },
    isLoading(){
      return this.statusLoadingCargo
    },
    isError(){
      return this.getCargoError != null
    },
    messageError(){
      if(this.isError){
        const status = normalizeStatus(this.getCargoError)
        const error = `err_` + status
        return this.vocabulary[error]
      } else {
        return null
      }
    },
    cargo(){
      return this.getCargo
    },
    timePeriod(){
      return this.cargo.delivery_min + '–' + this.cargo.delivery_max
    }
  },
}
</script>