<template>
  <base-input
      :label="vocabulary.label_email + ':' || 'Эл.почта'"
      @changeInputValue="handleValueEmail"
      @eventBlurInput="handleEventBlurInput"
      :type-notes="notes != null ? notes.type : null"
  > {{ notes != null ? notes.msg : '' }} </base-input>
</template>

<script>
import BaseInput from "@/shared/ui/BaseInput";
import {mapGetters} from "vuex";
import {validEmail} from "@/shared/libs/validation";
export default {
  name: "InputEmail",
  components: {BaseInput},
  data(){
    return{
      notes: null
    }
  },
  computed:{
    ...mapGetters(['getVocabulary']),
    vocabulary(){
      return this.getVocabulary
    }
  },
  methods:{
    handleValueEmail: function (str){
      let email = str
      const isValidEmail = validEmail(str)
      if(isValidEmail){
        this.notes = null
        this.$emit('valueEmail', email)
      } else {
        this.notes = {
          type: 'error',
          msg: this.vocabulary.incorrect_value
        }
        this.$emit('valueEmail', null)
      }
    },
    handleEventBlurInput: function (){}
  }

}
</script>
