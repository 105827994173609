export const getListNature = async lang => {
    let fetch = await getNature(lang); // запрашивает список по строке
    let res = await fetch.json()  // парсит json
    return res
}

const LIST_NATURE_URL = process.env.VUE_APP_BASEURL + process.env.VUE_APP_LIST_NATURE_URL;
export const getNature = (language = 'en') => {
    let url = new URL(LIST_NATURE_URL);
    // url = 'http://192.168.4.223:8000/departure-ops';
    Object.entries({
        language,
    }).forEach(([key, value]) => {
        if(value != null){
            url.searchParams.append(key, value)
        }
    });
    return fetch(url);
}