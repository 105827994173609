<template>
  <div class="wrap main-container">
    <div class="main-content" v-show="isStart || isResult || isOrder">

      <div class="main-content__title">
        <h1 class="base-h1" v-if="!isSuccess">
          {{ vocabulary.h_main || 'Рассчитайте стоимость доставки вашего груза из Китая' }}
        </h1>
      </div>

      <widget-calculate v-if="isStart"/>
      <widget-calculate-result v-if="isResult"/>
      <widget-order v-show="isOrder"/>

    </div>

    <widget-success v-show="isSuccess"/>

    <div class="main-illustration">
      <svg width="214" height="167" viewBox="0 0 271 212" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.3" class="second-point">
          <path d="M169.253 192.101C169.253 177.604 176.823 170.603 183.787 166.63C187.6 164.454 193.477 164.533 195.596 166.63C195.596 166.63 196.094 166.63 191.054 162.896C187.01 160.271 183.192 160.581 178.942 162.291C172.584 164.85 162.882 174.591 164.105 188.937C164.711 196.038 174.385 205.251 179.548 208.452C175.083 204.405 169.253 197.024 169.253 192.101Z" fill="url(#paint0_linear_327_8836)"/>
          <path d="M199.23 176.622C199.23 185.016 190.669 200.762 185.257 209.441C184.437 210.757 182.738 211.189 181.483 210.278C176.405 206.589 169.471 198.948 168.95 192.67C167.727 177.92 175.915 169.392 183.484 166.364C191.916 162.991 199.23 165.115 199.23 176.622Z" fill="url(#paint1_linear_327_8836)"/>
          <path d="M189.157 186.352C186.853 190.64 182.802 192.943 180.108 191.496C178.872 190.832 178.137 189.493 177.925 187.825C177.676 185.858 178.156 183.432 179.402 181.111C180.755 178.593 182.711 176.759 184.641 175.968C185.997 175.412 187.34 175.37 188.451 175.968C191.145 177.415 191.461 182.064 189.157 186.352Z" fill="url(#paint2_linear_327_8836)"/>
          <path d="M179.402 181.111C178.156 183.432 177.676 185.858 177.925 187.825C180.045 186.985 184.355 183.438 184.641 175.968C182.711 176.759 180.755 178.593 179.402 181.111Z" fill="#F8F8F8"/>
          <path d="M184.641 175.968C182.711 176.759 180.755 178.593 179.402 181.111L184.446 178.057C184.546 177.398 184.613 176.702 184.641 175.968Z" fill="#F8F8F8"/>
        </g>
        <g opacity="0.3" class="third-point">
          <path d="M231.008 41.5379C231.008 22.1969 241.107 12.8567 250.398 7.55602C255.485 4.65376 263.325 4.75961 266.152 7.55602C266.152 7.55602 266.817 7.55603 260.093 2.57567C254.698 -0.927176 249.604 -0.513866 243.935 1.76773C235.452 5.18145 222.508 18.1776 224.141 37.3162C224.949 46.7893 237.855 59.0807 244.743 63.3517C238.785 57.9523 231.008 48.1047 231.008 41.5379Z" fill="url(#paint3_linear_327_8836)"/>
          <path d="M271 20.8865C271 32.3394 259.054 54.0516 251.873 65.4454C251.047 66.7575 249.349 67.1938 248.071 66.3154C241.251 61.6289 231.324 50.9849 230.604 42.2964C228.972 22.6192 239.895 11.2409 249.994 7.20124C261.243 2.70173 271 5.53606 271 20.8865Z" fill="url(#paint4_linear_327_8836)"/>
          <path d="M257.562 33.8681C254.489 39.5887 249.084 42.6609 245.49 40.73C243.841 39.8441 242.86 38.0586 242.578 35.8331C242.245 33.2084 242.885 29.9716 244.548 26.876C246.353 23.5159 248.963 21.0696 251.537 20.014C253.346 19.2724 255.137 19.2173 256.62 20.014C260.214 21.9448 260.636 28.1475 257.562 33.8681Z" fill="url(#paint5_linear_327_8836)"/>
          <path d="M244.548 26.876C242.885 29.9716 242.245 33.2084 242.578 35.8331C245.405 34.7128 251.156 29.9805 251.537 20.014C248.963 21.0696 246.353 23.5159 244.548 26.876Z" fill="#F8F8F8"/>
          <path d="M251.537 20.014C248.963 21.0696 246.353 23.5159 244.548 26.876L251.277 22.8015C251.411 21.9218 251.5 20.9932 251.537 20.014Z" fill="#F8F8F8"/>
        </g>
        <g opacity="0.3" class="first-point">
          <path d="M6.8046 178.298C6.8046 159.519 16.6105 150.449 25.632 145.303C30.5715 142.485 38.1836 142.587 40.9293 145.303C40.9293 145.303 41.5744 145.303 35.0457 140.467C29.8072 137.066 24.8615 137.467 19.3562 139.682C11.1192 142.997 -1.44834 155.616 0.136552 174.199C0.921033 183.397 13.453 195.332 20.1407 199.479C14.3563 194.236 6.8046 184.675 6.8046 178.298Z" fill="url(#paint6_linear_327_8836)"/>
          <path d="M45.6361 158.246C45.6361 169.345 34.083 190.365 27.1064 201.446C26.2801 202.759 24.5833 203.193 23.3073 202.312C16.6873 197.738 7.10951 187.444 6.41233 179.035C4.82817 159.929 15.4338 148.881 25.2398 144.958C36.162 140.589 45.6361 143.341 45.6361 158.246Z" fill="url(#paint7_linear_327_8836)"/>
          <path d="M32.5883 170.851C29.6039 176.406 24.3559 179.389 20.8664 177.514C19.2653 176.654 18.3126 174.92 18.0387 172.759C17.7158 170.211 18.3369 167.068 19.9518 164.062C21.7047 160.799 24.2385 158.424 26.7382 157.399C28.4944 156.679 30.2338 156.625 31.6737 157.399C35.1631 159.274 35.5726 165.297 32.5883 170.851Z" fill="url(#paint8_linear_327_8836)"/>
          <path d="M19.9517 164.062C18.3367 167.068 17.7156 170.211 18.0386 172.759C20.7842 171.671 26.3679 167.076 26.7381 157.399C24.2384 158.424 21.7046 160.799 19.9517 164.062Z" fill="#F8F8F8"/>
          <path d="M26.7381 157.399C24.2384 158.424 21.7046 160.799 19.9517 164.062L26.4856 160.106C26.615 159.252 26.7017 158.35 26.7381 157.399Z" fill="#F8F8F8"/>
        </g>
        <defs>
          <linearGradient id="paint0_linear_327_8836" x1="179.858" y1="161.067" x2="179.858" y2="207.442" gradientUnits="userSpaceOnUse">
            <stop stop-color="#D3D3D3"/>
            <stop offset="1" stop-color="#747474"/>
          </linearGradient>
          <linearGradient id="paint1_linear_327_8836" x1="183.182" y1="167.575" x2="184.09" y2="211.746" gradientUnits="userSpaceOnUse">
            <stop stop-color="#EDEDED"/>
            <stop offset="1" stop-color="#A5A5A5"/>
          </linearGradient>
          <linearGradient id="paint2_linear_327_8836" x1="188.451" y1="175.968" x2="180.108" y2="191.496" gradientUnits="userSpaceOnUse">
            <stop stop-color="#BABABA"/>
            <stop offset="1" stop-color="#919191"/>
          </linearGradient>
          <linearGradient id="paint3_linear_327_8836" x1="245.156" y1="0.134688" x2="245.156" y2="62.0036" gradientUnits="userSpaceOnUse">
            <stop stop-color="#D3D3D3"/>
            <stop offset="1" stop-color="#747474"/>
          </linearGradient>
          <linearGradient id="paint4_linear_327_8836" x1="249.59" y1="8.81711" x2="250.802" y2="67.7459" gradientUnits="userSpaceOnUse">
            <stop stop-color="#EDEDED"/>
            <stop offset="1" stop-color="#A5A5A5"/>
          </linearGradient>
          <linearGradient id="paint5_linear_327_8836" x1="256.62" y1="20.014" x2="245.49" y2="40.73" gradientUnits="userSpaceOnUse">
            <stop stop-color="#BABABA"/>
            <stop offset="1" stop-color="#919191"/>
          </linearGradient>
          <linearGradient id="paint6_linear_327_8836" x1="20.5417" y1="138.097" x2="20.5417" y2="198.17" gradientUnits="userSpaceOnUse">
            <stop stop-color="#D3D3D3"/>
            <stop offset="1" stop-color="#747474"/>
          </linearGradient>
          <linearGradient id="paint7_linear_327_8836" x1="24.8475" y1="146.527" x2="26.0242" y2="203.746" gradientUnits="userSpaceOnUse">
            <stop stop-color="#EDEDED"/>
            <stop offset="1" stop-color="#A5A5A5"/>
          </linearGradient>
          <linearGradient id="paint8_linear_327_8836" x1="31.6737" y1="157.399" x2="20.8664" y2="177.514" gradientUnits="userSpaceOnUse">
            <stop stop-color="#BABABA"/>
            <stop offset="1" stop-color="#919191"/>
          </linearGradient>
        </defs>
      </svg>

    </div></div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import WidgetSuccess from "@/widgets/order/WidgetSuccess";
import WidgetOrder from "@/widgets/order/WidgetOrder";
import WidgetCalculateResult from "@/widgets/calculate/WidgetCalculateResult";
import WidgetCalculate from "@/widgets/calculate/WidgetCalculate";

export default {
  name: "MainBody",
  components: {WidgetCalculate, WidgetCalculateResult, WidgetOrder, WidgetSuccess},
  computed:{
    ...mapGetters(['isPathSuccess', 'isPathOrder', 'isPathResult', 'isPathStart', 'getVocabulary']),

    isSuccess(){
      return this.isPathSuccess
    },
    isOrder(){
      return this.isPathOrder
    },
    isResult(){
      return this.isPathResult
    },
    isStart(){
      return this.isPathStart
    },
    // activeFrame(){
    //   if (this.isPathSuccess){
    //     return 'WidgetSuccess'
    //   } else if (this.isPathOrder){
    //     return 'WidgetFormOrder'
    //   } else if (this.isPathResult){
    //     return 'WidgetResultCalculate'
    //   } else {
    //     return 'WidgetFormCalculate'
    //   }
    // },
    vocabulary(){
      return this.getVocabulary
    }
  },
  methods:{
    ...mapMutations(['updateVocabulary']),
  }
}
</script>

<style scoped>

</style>