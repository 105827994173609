<template>
  <div class="main-loader">
    <div class="spinner rounded-circle"></div>
  </div>
</template>

<script>
export default {
  name: "MainLoader"
}
</script>

<style scoped>
.main-loader{
  width: 100vw;
  height: 120vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: #F8F8F8;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner {
  width: 50px;
  height: 50px;
  border: 4px solid lightgray;
  border-top-color: gray;
  border-radius: 50%;
  animation: Rotate 1.2s linear 0s infinite forwards;
}

@media screen and (min-width: 768px){
  .main-loader{
    height: 100vh;
  }
}
</style>