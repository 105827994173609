<template>
  <div class="base-select">
    <div class="base-select__container">
      <div class="base-select__field"> {{ selectValue }}</div>
      <div class="base-select__icon">
        <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.55001 1.43751L5.35001 5.65001C5.30001 5.70001 5.24585 5.73534 5.18751 5.75601C5.12918 5.77701 5.06668 5.78751 5.00001 5.78751C4.93335 5.78751 4.87085 5.77701 4.81251 5.75601C4.75418 5.73534 4.70001 5.70001 4.65001 5.65001L0.437512 1.43751C0.320845 1.32084 0.262512 1.17501 0.262512 1.00001C0.262512 0.825006 0.325012 0.675007 0.450012 0.550007C0.575012 0.425007 0.720845 0.362507 0.887512 0.362507C1.05418 0.362507 1.20001 0.425007 1.32501 0.550007L5.00001 4.22501L8.67501 0.550006C8.79168 0.43334 8.93535 0.375006 9.10601 0.375006C9.27701 0.375006 9.42501 0.437506 9.55001 0.562506C9.67501 0.687506 9.73751 0.833339 9.73751 1.00001C9.73751 1.16667 9.67501 1.31251 9.55001 1.43751Z" fill="#141414"/>
        </svg>
      </div>
    </div>
    <div
        class="base-input__notes"
        :class="'base-input__notes--' + colorNotes"
    ><slot name="notes"></slot></div>
  </div>
</template>

<script>
export default {
  name: "BaseSelect",
  props:{
    typeNotes:{
      type: String,
      require: false
    },
    setSelectValue:{
      type: String,
      require: false,
      default(){
        return ''
      }
    }
  },
  data(){
    return{
      selectValue: this.setSelectValue
    }
  },
  watch:{
    setSelectValue(){
      this.selectValue = this.setSelectValue
    }
  },
  computed:{
    colorNotes(){
      if(this.typeNotes){
        return this.typeNotes
      } else {
        return 'default'
      }
    },
    isNotEmptyField(){
      return this.inputValue.length !== 0;
    },
  },

}
</script>

<style>
.base-select{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.base-select__container{
  background: #FFFFFF;
  border: 1px solid #E6E6E6;
  border-radius: 12px;
  height: 48px;
  padding: 0 15px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #141414;
  cursor: pointer;
  transition: 200ms;
}

.base-select__container:hover{
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.03);
}

.base-select__icon{
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>