<template>
  <selector-point
      :type-point="vocabulary.label_departure + ':' || 'Откуда:'"
      :is-now-show-list="true"
      :set-selected-point="getPointDeparture"
      :func-get-list="funcGetListDeparture"
      @selectedPoint="handleSelectedDeparture"
  />
</template>

<script>
import SelectorPoint from "@/entities/cargo/ui/SelectorPoint";
import {getListDeparture} from "@/entities/cargo/api/getListDeparture";
import {mapGetters} from "vuex";
export default {
  name: "SelectorDeparture",
  components: {SelectorPoint},
  emits:['selectedDeparture'],
  computed:{
    ...mapGetters(['getVocabulary', 'getDeparture']),
    vocabulary(){
      return this.getVocabulary
    },
    getPointDeparture(){
      return this.getDeparture
    }
  },
  data(){
    return{
      funcGetListDeparture: getListDeparture,
    }
  },
  methods:{
    handleSelectedDeparture: function (obj){
      this.$emit('selectedDeparture', obj)
    }
  }
}
</script>