export default {
    mutations: {
        updatePathSuccess(state, flag){
            state.pathSuccess = flag
            if(flag){
                state.pathOrder = false
            }
        },
        updatePathOrder(state, flag){
            state.pathOrder = flag
            if(flag){
                state.pathResult = false
            }
        },
        updatePathResult(state, flag){
            state.pathResult = flag
            if(flag){
                state.pathStart = false
                state.pathOrder = false
            }
        },
        updatePathStart(state, flag){
            state.pathStart = flag
            if(flag){
                state.pathSuccess = false
                state.pathResult = false
            }
        },
        updateCurrentLang(state, str){
            state.currentLang = str
        },
        updateVocabulary(state, obj){
            state.vocabulary = obj
        },
        updateUID(state, str){
            state.uid = str
        }
    },
    state: {
        pathSuccess: false,
        pathOrder: false,
        pathResult: false,
        pathStart: true,

        currentLang: 'ru',

        languages:{
            ru: true,
            en: false,
            cn: false,
        },

        uid: null,

        vocabulary:{
            // label_departure:'Откуда',
            // label_destination:'Куда',
            // label_nature:'Характер груза',
            // label_weight:'Вес',
            // label_volume:'Объем',
            // label_name:'Имя',
            // label_tel:'Телефон',
            // label_email:'Эл. почта',
            // h_main:'Рассчитайте стоимость доставки вашего груза из Китая',
            // h_calculate:'Калькулятор',
            // h_order:'Оформление заявки',
            // p_order:'Оставьте свои контакты, и наш менеджер свяжется с вами',
            // h_success:'Ваша заявка принята',
            // p_success:'Наш менеджер свяжется с вами в ближайшее время',
            // button_calculate:'Рассчитать',
            // button_order:'Перейти к оформлению',
            // button_post:'Отправить',
            // button_home:'Вернуться на главную',
            // h_params:'Параметры груза',
            // postfix_weight:'кг',
            // postfix_volume:'м3',
            // h_cost:'Стоимость',
            // h_time:'Срок доставки',
            // postfix_time:'дней',
            // button_call:'Позвонить',
            // button_lang: 'Выбрать язык',
            // err_408:'Превышено время ожидания. Попробуйте позже',
            // err_422:'Неизвестная ошибка',
            // err_404:'Не удалось рассчитать доставку. Попробуйте изменить параметры расчета.',
            // err_400:'Не удалось рассчитать доставку. Попробуйте позже.',
            // err_500:'Ошибка сервера. Попробуйте позже.',
        }
    },
    getters: {
        isPathSuccess(state){
            return state.pathSuccess
        },
        isPathOrder(state){
            return state.pathOrder
        },
        isPathResult(state){
            return state.pathResult
        },
        isPathStart(state){
            return state.pathStart
        },
        getVocabulary(state){
            return state.vocabulary
        },
        getLanguages(state){
            return state.languages
        },
        getCurrentLang(state){
            return state.currentLang
        },
        getUID(state){
            return state.uid
        },
    }
}