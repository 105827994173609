import {Cargo} from "@/entities/cargo/store/Cargo";
import {isStatusSuccess} from "@/shared/libs/validation";

export const postOrderForm = async (departure, destination, weight, volume, nature, uid, ip, location, user_name, user_email, user_tel, total, delivery_min, delivery_max) =>{
    const params = new Cargo(departure, destination, weight, volume, nature, uid, ip, location, user_name, user_email, user_tel, total, delivery_min, delivery_max)
    const response = await postOrderFetch(params) // fetch
    const error = isStatusSuccess(response.status) ? null : response.status // если НЕуспех, тогда отправляем код ошибки
    return {error} // возвращает статус
}

// API
const ORDER_URL = process.env.VUE_APP_BASEURL + process.env.VUE_APP_ORDER_URL;
const postOrderFetch = async obj => {
    let url = new URL(ORDER_URL);
    const request = {
        method: 'POST',
        body: JSON.stringify(obj),
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            'accept': 'application/json',
        }
    };
    let status
    try {
        const response = await fetch(url, request);
        status = response.status;
    } catch (e) {
        status = 500
    }
    return {status}
}