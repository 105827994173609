<template>
  <base-input
      model="float"
      :label="vocabulary.label_weight + ':' ||  'Вес:'"
      :set-input-value="setValueWeight"
      :postfix="vocabulary.postfix_weight"
      :focus="isFocus"
      ref="inputWeight"
      @changeInputValue="handleValueWeight"
      @eventBlurInput="handleEventBlurInput"
      :type-notes="notes != null ? notes.type : null"
  > {{ notes != null ? notes.msg : '' }} </base-input>
</template>

<script>
import BaseInput from "@/shared/ui/BaseInput";
import {mapGetters, mapMutations} from "vuex";
import {validMaxSymbolsNumber} from "@/shared/libs/validation";
export default {
  name: "InputWeight",
  components: {BaseInput},
  emits:['valueWeight'],
  data(){
    return{
      valueWeight: '',
      setValueWeight: '',
      focus: this.statusFocusWeight,
      notes: null
    }
  },
  computed:{
    ...mapGetters(['statusFocusWeight', 'getVocabulary', 'getWeight']),
    isFocus(){
      return this.statusFocusWeight
    },
    vocabulary(){
      return this.getVocabulary
    },
    getValueWeight(){
      return this.getWeight
    }
  },
  beforeMount() {
    this.setValueWeight = this.getValueWeight ? this.getValueWeight : ''
  },
  watch:{
    valueWeight(){
      this.$emit('valueWeight', this.valueWeight)
    },
  },
  methods:{
    ...mapMutations(['commandFocusWeight']),
    handleValueWeight: function (str){
      if(validMaxSymbolsNumber(str) != null){
        this.valueWeight = str
        this.notes = null
      } else {
        this.valueWeight = null
        this.notes = {
          type: 'error',
          msg: this.vocabulary.symbols_validator
        }
      }
    },
    handleEventBlurInput: function (){
      this.commandFocusWeight(false)
    },
    handleCommandFocus: function (){
      this.$refs.inputWeight.commandFocusInput()
    },
    // validatedValue: function(value){
    //   if(value !== ''){
    //     // если не пусто
    //     const mutationValue = this.setValueWeight = validSymbolsNumber(value)
    //     return Number(mutationValue).toFixed(2)
    //   } else {
    //     // если пусто
    //     return null
    //   }
    // },
  },
  mounted() {
    if(this.isFocus){
      this.handleCommandFocus()
    }
  },
}
</script>