<template>
<base-form>
  <template v-slot:title>
    <div class="base-form__back">
      <base-button-back @click="handleClickBack"/>
    </div>
    <h2 class="base-h2"> {{ vocabulary.h_order }} </h2>
  </template>
  <template v-slot:description>
    <p class="base-description"> {{ vocabulary.p_order }} </p>
  </template>
  <template v-slot:content>
    <post-order/>
  </template>
</base-form>
</template>

<script>
import BaseForm from "@/shared/ui/BaseForm";
import BaseButtonBack from "@/shared/ui/BaseButtonBack";
import PostOrder from "@/features/post-order/PostOrder";
import {mapGetters, mapMutations} from "vuex";
export default {
  name: "WidgetOrder",
  components: {PostOrder, BaseButtonBack, BaseForm},
  props:{
    isPageInner:{
      type: Boolean,
      require: false,
      default(){
        return false
      }
    }
  },
  computed:{
    ...mapGetters(['getVocabulary']),
    vocabulary(){
      return this.getVocabulary
    }
  },
  methods:{
    ...mapMutations(['updatePathResult']),
    handleClickBack: function (){
      this.updatePathResult(true)
    },
  }
}
</script>

<style scoped>

</style>