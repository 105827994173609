export const getListDestination = async (lang, str) => {
    let listDefault
    let listSort

    let fetch = await getDestination(lang, false ,str);
    let response = await fetch.json();

    if(response.length === 0){
        // пустой ответ
        response = null
    }

    if(response == null){
        // ничего не найдено
        fetch = await getDestination(lang, true); // запрашивает дефолтный список по строке
        response = await fetch.json(); // парсит json
        listDefault = true // флаг о том, что этот список дефолтный
        listSort = response // записываем результат
    } else {
        // есть ответ по поиску
        listDefault = false  // флаг о том, что этот список НЕдефолтный
        // listSort = sortByKey(response, 'name', str); // сортирует список городов по ключу
        listSort = response // сортирует список городов по ключу
    }

    return {listDefault, listSort}
}

const DESTINATION_POINTS_URL = process.env.VUE_APP_BASEURL + process.env.VUE_APP_DESTINATION_POINTS_URL;

export const getDestination = (language, all, search) => {
    const url = new URL(DESTINATION_POINTS_URL);
    Object.entries({
        language,
        all,
        search
    }).forEach(([key, value]) => {
        if(value != null){
            url.searchParams.append(key, value)
        }
    });
    return fetch(url);
}
