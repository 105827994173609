<template>
  <div
      class="base-form"
  >
    <div class="base-form__title"><slot name="title"></slot></div>
    <div class="base-form__description"><slot name="description"></slot></div>
    <div class="base-form__content"><slot name="content"></slot></div>
  </div>
</template>

<script>
export default {
  name: "BaseForm",
  props:{
    fullScreen:{
      type: Boolean,
      default(){
        return false
      }
    },
    fillScreen:{
      type: Boolean,
      default(){
        return false
      }
    },
  }
}
</script>

<style>
.base-form{
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7px);
  border-radius: 24px 24px 0 0;
  padding: 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.base-form--full{
  height: 80vh;
}
.base-form--fill{
  background: #F9F9F9;
}

.base-form__content{
  margin-top: 20px;
}

.base-form__back{
  position: absolute;
  top: -20px;
  left: 20px;
  transform: translateY(-100%);
}

.base-form__h3{
  margin-bottom: 15px;
}

.base-form__description{
  margin: 0 auto;
  margin-top: 10px;
  max-width: 80%;
}

.base-form__container-button{
  padding-top: 12px;
  margin-top: 12px;
  border-top: 1px solid #E6E6E6;
}

.base-form__container-result{
  margin-top: 20px;
}

@media screen and (min-width: 768px) {
  .base-form {
    border-radius: 24px;
    position: relative;
    bottom: auto;
    left: auto;
    right: auto;
    width: 100%;
    max-width: 405px;
  }
  .base-form__back{
    left: 0;
  }

}
@media screen and (min-width: 1000px){
  .base-form__back{
    left: -20px;
    transform: translateX(-100%);
    top: 0;
  }
}
</style>