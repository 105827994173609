<template>
  <label class="card-nature">
    <span class="card-nature__content">{{ nature[keyNameByLang] }}</span>
    <span class="card-nature__checkbox">
      <base-input-radio
          name-group="nature"
          :is-picked="isPicked"
          @eventChange="handleEventSelect"
      />
    </span>
  </label>
</template>

<script>
import BaseInputRadio from "@/shared/ui/BaseInputRadio";
import {mapGetters} from "vuex";
export default {
  name: "SelectNature",
  components: {BaseInputRadio},
  props:{
    nature:{
      type: Object,
      require: true
    },
    isPicked:{
      type: Boolean,
      default: false,
    }
  },
  computed:{
    ...mapGetters(['getCurrentLang']),
    keyNameByLang(){
      let lang = this.getCurrentLang
      if(lang !== 'ru' && lang !== 'cn'){
        lang = 'en'
      }
      return `name_` + lang
    },
  },
  methods:{
    handleEventSelect: function (){
      this.$emit('selectNature', this.nature)
    }
  }
}
</script>

<style>
.card-nature{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  padding: 15px;
  padding-top: 15px;
}

.card-nature__content{
  /*font-weight: 700;*/
  font-size: 14px;
  line-height: 17px;
  color: #333333;
  flex: 1;
  text-transform: capitalize;
}

.card-nature__checkbox{
  margin-left: 10px;
}

@media screen and (min-width: 768px){
  .card-nature__checkbox{
    display: none;
  }
}
</style>