<template>
  <div class="base-sausage "
       :class="{
        'skeleton__animation' : isLoading
       }"
  >
    <div class="base-sausage__cell">
      <div class="base-sausage__title"><slot name="first-cell-label"></slot></div>
      <div class="base-sausage__highlight"><slot name="first-cell-value"></slot></div>
    </div>
    <div class="base-sausage__cell">
      <div class="base-sausage__title"><slot name="last-cell-label"></slot></div>
      <div class="base-sausage__highlight"><slot name="last-cell-value"></slot></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseSausage",
  props:{
    isLoading:{
      type: Boolean,
      default(){
        return false
      }
    }
  }
}
</script>

<style>

.base-sausage{
  background: #FFFFFF;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  min-height: 91px;
}

.base-sausage.skeleton__animation{
  box-shadow: none;
  height: 91px;
}

.base-sausage__cell{
  width: 50%;
  text-align: center;
}

.base-sausage__cell:first-child{
  border-right: 1px solid #E6E6E6;
}

.base-sausage__title{
  margin-bottom: 8px;
}

.base-sausage__highlight{
  /*font-weight: 700;*/
  font-size: 20px;
  line-height: 24px;
  color: #EE1B2C;
}
</style>