<template>
    <input
        class="base-input-radio__hide"
        type="radio"
        :name="nameGroup"
        :checked="isPicked"
        @input="eventClick()"
    >
    <span class="base-input-radio__checkbox"></span>
</template>

<script>
export default {
  name: "BaseInputRadio",
  props:{
    nameGroup:{
      type: String,
      require: true,
    },
    isPicked:{
      type: Boolean,
      request: false,
    },
  },
  emits:['eventChange'],
  methods:{
    eventClick: function (){
      this.$emit('eventChange') // эмитит событие
    }
  }
}
</script>

<style>
.base-input-radio__hide{
  display: none;
}

.base-input-radio__checkbox{
  display: block;
  min-width: 20px;
  width: 20px;
  height: 20px;
  border: 1px solid #A2A2A2;
  border-radius: 4px;
  background: transparent;
  cursor: pointer;
}

.base-input-radio__hide:checked ~ .base-input-radio__checkbox{
  background: #1B23EE url("../assets/icons/check.svg") no-repeat center / 10px;
  border-color: #1B23EE;
}
</style>