<template>
  <base-input
      model="float"
      :label="vocabulary.label_volume + ':' || 'Объем:'"
      :postfix="vocabulary.postfix_volume"
      :set-input-value="setValueVolume"
      ref="inputVolume"
      @changeInputValue="handleValueVolume"
      @eventBlurInput="handleEventBlurInput"
      :type-notes="notes != null ? notes.type : null"
  > {{ notes != null ? notes.msg : '' }} </base-input>
</template>

<script>
import BaseInput from "@/shared/ui/BaseInput";
import {mapGetters, mapMutations} from "vuex";
import {validMaxSymbolsNumber} from "@/shared/libs/validation";
export default {
  name: "InputVolume",
  components: {BaseInput},
  emits:['valueVolume'],
  data(){
    return{
      valueVolume: '',
      setValueVolume: '',
      notes: null
    }
  },
  computed:{
    ...mapGetters(['statusFocusVolume', 'getVocabulary', 'getVolume']),
    isFocus(){
      return this.statusFocusVolume
    },
    vocabulary(){
      return this.getVocabulary
    },
    getValueVolume(){
      return this.getVolume
    }
  },
  beforeMount() {
    this.setValueVolume = this.getValueVolume ? this.getValueVolume : ''
  },
  watch:{
    valueVolume(){
      this.$emit('valueVolume', this.valueVolume)
    }
  },
  methods:{
    ...mapMutations(['commandFocusVolume']),
    handleValueVolume: function (str){
      if(validMaxSymbolsNumber(str) != null){
        this.valueVolume = str
        this.notes = null
      } else {
        this.valueVolume = null
        this.notes = {
          type: 'error',
          msg: this.vocabulary.symbols_validator
        }
      }
    },
    handleEventBlurInput: function (){
      this.commandFocusVolume(false)
    },
    handleCommandFocus: function (){
      this.$refs.inputVolume.commandFocusInput()
    },
  },
  mounted() {
    if(this.isFocus){
      this.handleCommandFocus()
    }
  },
}
</script>

<style scoped>

</style>