<template>
  <ul class="base-list">
    <slot></slot>
  </ul>
</template>

<script>
export default {
  name: "BaseList",
}
</script>

<style>
.base-list{
  background: #FFFFFF;
  border-radius: 12px;
  list-style: none;
  overflow: hidden;
}

.base-list__item{
  border-bottom: 1px solid #F8F8F8;

  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #141414;
  cursor: pointer;
  transition: 200ms;
}

.base-list__item--pd{
  padding: 15px;
}

.base-list__item:hover{
  background: #F8F8F8
}

.base-list__item:last-child{
  border-bottom: 0;
}
@media screen and (min-width: 768px){
  .base-list{
    border-radius: 0;
  }
}
</style>