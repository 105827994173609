import {Cargo} from "@/entities/cargo/store/Cargo";
import {isStatusSuccess} from "@/shared/libs/validation";

// Получает расчет карго и определяет ошибки
export const getCalculateCargo = async (departure, destination, weight, volume, nature, uid, ip, location) =>{
    const params = new Cargo(departure, destination, weight, volume, nature, uid, ip, location) // объект для расчета cargo
    const response = await postCalculate(params) // fetch
    const cargo = isStatusSuccess(response.status) ? response.result : null // если успех, тогда записываем
    const error = isStatusSuccess(response.status) ? null : response.status // если НЕуспех, тогда отправляем код ошибки
    return {cargo, error} // возвращает расчет и статус
}

// API
const CALCULATE_POINTS_URL = process.env.VUE_APP_BASEURL + process.env.VUE_APP_CALCULATE_POINTS_URL;
const postCalculate = async obj => {
    let url = new URL(CALCULATE_POINTS_URL);
    const request = {
        method: 'POST',
        body: JSON.stringify(obj),
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            'accept': 'application/json',
        }
    };
    let status, result
    try {
        const response = await fetch(url, request);
        status = response.status;
        result = await response.json()
    } catch (e) {
        status = 500
        result = null
    }
    return {status, result}
}