<template>
  <a @click.stop="eventClick" class="base-button-cross">
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.00768 5.83274L8.36846 9.19352C8.4805 9.30174 8.63057 9.36162 8.78634 9.36027C8.94211 9.35891 9.09112 9.29643 9.20127 9.18628C9.31142 9.07613 9.3739 8.92713 9.37525 8.77136C9.3766 8.61559 9.31672 8.46552 9.2085 8.35347L5.84773 4.9927L9.2085 1.63192C9.31672 1.51987 9.3766 1.36981 9.37525 1.21404C9.3739 1.05827 9.31142 0.909262 9.20127 0.799112C9.09112 0.688963 8.94211 0.626483 8.78634 0.625129C8.63057 0.623776 8.4805 0.683657 8.36846 0.791875L5.00768 4.15265L1.6469 0.791875C1.53435 0.686332 1.38515 0.628717 1.23088 0.631222C1.0766 0.633728 0.929355 0.696156 0.82029 0.805298C0.711225 0.91444 0.6489 1.06173 0.646504 1.21601C0.644108 1.37029 0.701828 1.51944 0.807451 1.63192L4.16763 4.9927L0.806857 8.35347C0.750116 8.40828 0.704856 8.47383 0.673721 8.54631C0.642585 8.6188 0.626196 8.69675 0.625511 8.77564C0.624825 8.85452 0.639857 8.93275 0.669728 9.00576C0.6996 9.07877 0.743713 9.1451 0.799494 9.20088C0.855274 9.25666 0.921606 9.30078 0.994618 9.33065C1.06763 9.36052 1.14586 9.37555 1.22474 9.37487C1.30363 9.37418 1.38158 9.35779 1.45406 9.32666C1.52655 9.29552 1.5921 9.25026 1.6469 9.19352L5.00768 5.83274Z" fill="#141414"/>
    </svg>
  </a>
</template>

<script>
export default {
  name: "BaseButtonCross",
  emits: ['eventClean'],
  methods:{
    eventClick: function (){
      this.$emit('eventClean')
    }
  }
}
</script>

<style>
.base-button-cross{
  /*display: inline-block;*/
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 20px;
  min-width: 20px;
  height: 20px;
  background: #E6E6E6;
  border-radius: 50%;
  cursor: pointer;
}
</style>