const LANG_URL = process.env.VUE_APP_BASEURL + process.env.VUE_APP_LANG_URL;
export const getLang = async (language) => {
    let url = new URL(LANG_URL);
    // url = 'http://192.168.4.223:8000/departure-ops';
    Object.entries({
        language
    }).forEach(([key, value]) => {
        if(value != null){
            url.searchParams.append(key, value)
        }
    });

    return (await fetch(url)).json();
}