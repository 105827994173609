<template>
  <form class="form" @submit.prevent>
    <div class="form__container-input">
      <input-name @valueName="handleValueName"/>
    </div>
    <div class="form__container-input">
      <input-tel @valueTel="handleValueTel"/>
    </div>
    <div class="form__container-input">
      <input-email @valueEmail="handleValueEmail"/>
    </div>
    <div class="base-form__container-button">
      <base-button
          :is-disabled="lockButtonOrder"
          :is-loading="isLoading"
          @click.prevent="handleClickPostOrder"
      >
        {{ vocabulary.button_post }}
      </base-button>
    </div>
  </form>
</template>

<script>
import BaseButton from "@/shared/ui/BaseButton";
import InputName from "@/entities/user/InputName";
import InputTel from "@/entities/user/InputTel";
import InputEmail from "@/entities/user/InputEmail";
import {postOrderForm} from "@/features/post-order/postOrderCargo";
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "PostOrder",
  components: {InputEmail, InputTel, InputName, BaseButton},
  data(){
    return{
      user_name: null,
      user_tel: null,
      user_email: null,
      lockButtonOrder: true,
      isLoading: false
    }
  },
  computed:{
    ...mapGetters(['getVocabulary', 'getDeparture', 'getDestination', 'getNature', 'getWeight', 'getVolume', 'getCargo', 'getUID']),
    vocabulary(){
      return this.getVocabulary
    },
    pointDeparture(){
      return this.getDeparture
    },
    pointDestination(){
      return this.getDestination
    },
    valueWeight(){
      return this.getWeight
    },
    valueVolume(){
      return this.getVolume
    },
    valueNature(){
      return this.getNature
    },
    valueCargo(){
      return this.getCargo
    },
    uid(){
      return this.getUID
    }
  },
  watch:{
    user_name(){
      this.handleFullnessFormOrder()
    },
    user_tel(){
      this.handleFullnessFormOrder()
    },
    user_email(){
      this.handleFullnessFormOrder()
    }
  },
  methods:{
    ...mapMutations(['updatePathSuccess']),
    handleClickPostOrder: async function (){
      this.isLoading = true// начало загрузки
      // обнуление статуса заполненых полей
      // выключение окна ошибок
      const response = await postOrderForm(
          this.pointDeparture,
          this.pointDestination,
          this.valueWeight,
          this.valueVolume,
          this.valueNature,
          this.uid,'-','-',
          this.user_name,
          this.user_email,
          this.user_tel,
          this.valueCargo != null ? this.valueCargo.total : -1,
          this.valueCargo != null ? this.valueCargo.delivery_min : -1,
          this.valueCargo != null ? this.valueCargo.delivery_max : -1,
      ) // fetch
       // список тарифов [] / null
      console.log(response)
      // const error = response.tariffError // ошибка {} / null
      this.updatePathSuccess(true) // включение окна результатов
      // записываем ошибку
      this.isLoading = false // загрузка закончена
    },

    // Обрабатывает заполнение формы
    handleFullnessFormOrder: function (){
      if(this.user_name != null && this.user_tel != null && this.user_email != null){
        // форма заполнена полностью
        this.lockButtonOrder = false // разблокирует кнопку "Отправить"
      } else if (this.user_name == null || this.user_tel == null || this.user_email == null){
        // форма очищена
        this.lockButtonOrder = true // блокирует кнопку "Отправить"
      } else {
        // форма заполнена не полностью
        this.lockButtonCalculate = true // блокирует кнопку "Отправить"
      }
    },

    handleValueName: function (str){
      this.user_name = str
    },
    handleValueTel: function (str){
      this.user_tel = str
    },
    handleValueEmail: function (str){
      this.user_email = str
      console.log(this.user_email)
    }
  }
}
</script>

<style>

</style>