<template>
  <a :href="'tel:' + tel" class="card-tel">
    <card-contact :tel="tel" :country="country"/>
    <div class="card-tel__icon">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.29422 13.425L5.89422 12.025C5.70565 11.8388 5.46708 11.7113 5.20747 11.658C4.94786 11.6048 4.67837 11.628 4.43172 11.725L2.72547 12.4062C2.47621 12.5074 2.26249 12.6801 2.11122 12.9026C1.95996 13.125 1.87793 13.3873 1.87547 13.6562V16.7812C1.87692 16.9642 1.91538 17.145 1.98855 17.3128C2.06171 17.4805 2.16807 17.6317 2.3012 17.7572C2.43434 17.8828 2.5915 17.9801 2.76323 18.0433C2.93496 18.1065 3.11771 18.1343 3.30047 18.125C15.2567 17.3812 17.6692 7.25625 18.1255 3.38125C18.1466 3.19096 18.1273 2.99834 18.0687 2.81606C18.0101 2.63379 17.9135 2.46599 17.7854 2.32371C17.6573 2.18142 17.5005 2.06787 17.3254 1.99053C17.1502 1.9132 16.9607 1.87382 16.7692 1.875H13.7505C13.4811 1.87579 13.2181 1.95716 12.9953 2.10864C12.7726 2.26011 12.6002 2.47477 12.5005 2.725L11.8192 4.43125C11.7191 4.67691 11.6935 4.94665 11.7457 5.20675C11.798 5.46686 11.9257 5.70581 12.113 5.89375L13.513 7.29375C13.513 7.29375 12.7067 12.75 7.29422 13.425Z" fill="#1B23EE"/>
      </svg>
    </div>
  </a>
</template>

<script>
import CardContact from "@/entities/contacts/CardContact";
export default {
  name: "CallByPhone",
  components: {CardContact},
  props:{
    tel: {
      type: String,
    },
    country:{
      type: String,
      validator(value){
        return ['ru', 'en', 'cn', 'kg'].includes(value)
      }
    }
  },
}
</script>

<style>
.card-tel{
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  padding: 15px;
}
@media screen and (min-width: 768px){
  .card-tel{
    padding: 0;
  }
}
</style>