<template>
  <base-dropdown :command-visible="commandVisibleDropdown">
    <!-- input -->
    <template v-slot:head>
      <a class="selector-lang__head" @click="handleClickLink">
        {{ selectedItemLang }}
        <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.55001 1.43751L5.35001 5.65001C5.30001 5.70001 5.24585 5.73534 5.18751 5.75601C5.12918 5.77701 5.06668 5.78751 5.00001 5.78751C4.93335 5.78751 4.87085 5.77701 4.81251 5.75601C4.75418 5.73534 4.70001 5.70001 4.65001 5.65001L0.437512 1.43751C0.320845 1.32084 0.262512 1.17501 0.262512 1.00001C0.262512 0.825006 0.325012 0.675007 0.450012 0.550007C0.575012 0.425007 0.720845 0.362507 0.887512 0.362507C1.05418 0.362507 1.20001 0.425007 1.32501 0.550007L5.00001 4.22501L8.67501 0.550006C8.79168 0.43334 8.93535 0.375006 9.10601 0.375006C9.27701 0.375006 9.42501 0.437506 9.55001 0.562506C9.67501 0.687506 9.73751 0.833339 9.73751 1.00001C9.73751 1.16667 9.67501 1.31251 9.55001 1.43751Z" fill="#141414"/>
        </svg>
      </a>
    </template>
    <!-- крестик в шторке -->
    <template v-slot:cross>
      <base-button-cross  @click="handleClickClose"/>
    </template>

    <!-- заголовок в шторке -->
    <template v-slot:headline>
      <div class="base-driver__title">
        <h2 class="base-h2" style="text-align: left"> {{ vocabulary.button_lang }} </h2>
      </div>
    </template>

    <!-- контент -->
    <template v-slot:body>
      <base-list>
        <li
            v-for="(itemLang, index) in Object.keys(languages)"
            :key="index"
            class="base-list__item"
        >
          <select-land
              :is-picked="itemLang === selectedItemLang"
              :lang="itemLang"
              @selectLang="handleClickItem"
          />
        </li>
      </base-list>
    </template>

  </base-dropdown>
</template>

<script>
import BaseDropdown from "@/shared/ui/BaseDropdown";
import BaseButtonCross from "@/shared/ui/BaseButtonСross";
import BaseList from "@/shared/ui/BaseList";
import SelectLand from "@/features/select-lang/SelectLang";
import {mapGetters, mapMutations} from "vuex";
import {getLang} from "@/features/select-lang/api/getLang";
export default {
  name: "SelectorLang",
  components: {SelectLand, BaseList, BaseButtonCross, BaseDropdown},
  data(){
    return{
      commandVisibleDropdown: false,
      selectedItemLang: '',
    }
  },
  computed:{
    ...mapGetters(['getVocabulary', 'getLanguages', 'getCurrentLang']),
    vocabulary(){
      return this.getVocabulary
    },
    languages(){
      return this.getLanguages
    },
    currentLang(){
      return this.getCurrentLang
    }
  },
  async beforeMount() {
    const res = await getLang('ru')
    this.updateVocabulary(res)
    this.selectedItemLang = this.getCurrentLang
  },
  methods:{
    ...mapMutations(['updateCurrentLang', 'updateVocabulary']),
    // Обрабатывает событие click из base-list
    handleClickItem: async function (str){
      this.selectedItemLang = str // записываем выбранное значение
      this.updateCurrentLang(str)
      const res = await getLang(str)
      this.updateVocabulary(res)
      this.commandVisibleDropdown = false // закрывает base-dropdown
    },

    handleClickLink: function (){
      this.commandVisibleDropdown = true // открывает дропдаун
    },
    handleClickClose: function (){
      this.commandVisibleDropdown = false // закрывает дропдаун
    }
  }
}
</script>

<style>
.selector-lang__head{
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #141414;
  padding-right: 15px;
  position: relative;
  cursor: pointer;
  text-transform: uppercase;
  display: inline-block;
}

.selector-lang__head svg{
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

</style>

<style scoped>
/*.base-list__item{*/
/*  padding-top: 13px;*/
/*  padding-bottom: 13px;*/
/*}*/
</style>