<template>
  <a class="base-tag" @click="handleClick">
    <div class="base-tag__container">
      <div class="base-tag__content"> {{ tagName }} </div>
      <div class="base-tag__icon"><slot name="tag-icon"></slot></div>
    </div>
  </a>
</template>

<script>
export default {
  name: "BaseTag",
  emits:['clickTag'],
  props:{
    tagName:{
      type: String
    }
  },
  methods: {
    handleClick: function (){
      this.$emit('clickTag')
    }
  }
}
</script>

<style>
.base-tag{
  height: 32px;
  display: inline-block;
  padding: 7px 15px;
  background: #E6E6E6;
  border-radius: 4px;
  cursor: pointer;
}
.base-tag__container{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.base-tag__content{
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #141414;
  white-space: nowrap;
}
.base-tag__icon{
  display: inline-block;
}
.base-tag__icon svg{
  margin-left: 15px;
}
</style>