<template>
  <label class="card-lang">
    <card-lang :lang="lang"/>
    <span class="card-lang__checkbox">
      <base-input-radio
          name-group="lang"
          :is-picked="isPicked"
          @eventChange="handleEventSelect"
      />
    </span>
  </label>
</template>

<script>
import BaseInputRadio from "@/shared/ui/BaseInputRadio";
import CardLang from "@/entities/lang/CardLang";
export default {
  name: "SelectLand",
  components: {CardLang, BaseInputRadio},
  emits:['selectLang'],
  props:{
    lang:{
      type: String,
      validator(value){
        return ['ru', 'en', 'cn'].includes(value)
      },
      require: true
    },
    isPicked:{
      type: Boolean,
      default: false,
    }
  },
  methods:{
    handleEventSelect: function (){
      // TODO Сюда логику перевода

      this.$emit('selectLang', this.lang) // рассказывает, какой язык выбран
    }
  }

}
</script>

<style>
.card-lang{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  padding: 15px;
  padding-top: 15px;
}

.card-lang__checkbox{
  margin-left: 10px;
}

@media screen and (min-width: 768px){
  .card-lang__checkbox{
    display: none;
  }
}
</style>