import {handleWindowResize} from "@/shared/libs/windowListener";

export function useScrollToInput(element){
    const access = element
    const ios = navigator.appVersion.indexOf('iPhone') >= 0

    // function scrollToInput(){
    if(handleWindowResize() < 768 && !ios){
        // для накладывания клавиатуры на поля в MI
        // document.body.classList.add('paddingForGboard')
        access.scrollIntoView({behavior: 'smooth'}, true);
    }
}
export function useScrollToTop(){
    // const ios = navigator.appVersion.indexOf('iPhone') >= 0
        console.log('ScrollToTop')
    if(handleWindowResize() < 768){
        // window.scrollTo(0, 0);
        window.scrollTo({ top: 0, behavior: 'smooth' });
        // access.scrollIntoView({behavior: 'smooth'}, true);
    }
}
export function useScrollToTopDriver(element){
    console.log(element)
    if(handleWindowResize() < 768){
        // window.scrollTo(0, 0);
        element.scrollIntoView({behavior: 'smooth'}, true);
        // access.scrollIntoView({behavior: 'smooth'}, true);
    }
}