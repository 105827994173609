<template>
    <div class="base-input">
      <label
          class="base-input__container"
          :class="'base-input__container--' + colorNotes"
          ref="container-input"
      >
        <span v-show="label" class="base-input__label"> {{ label }} </span>
        <input
            :placeholder="placeholder"
            class="base-input__field"
            type="text"
            ref="input"
            v-model="inputValue"
            @keydown.enter="commandBlurInput"
            @keydown.tab="commandBlurInput"
            @input='e => this.inputValue = e.target.value'
            v-debounce:500="changeInputValue"
            @paste.prevent="pasteInputValue($event)"
            @blur="blurInput"
            @focus="focusInput($el)"
        >
        <base-button-cross
            v-if="isNotEmptyField && !notCross"
            @eventClean="handleEventClean"
        />
        <span v-show="postfix" class="base-input__postfix">{{ postfix }}</span>
      </label>
      <div
          class="base-input__notes"
          :class="'base-input__notes--' + colorNotes"
      >
        <slot></slot>
      </div>
    </div>
</template>

<script>
import BaseButtonCross from "@/shared/ui/BaseButtonСross";
import {useScrollToInput} from "@/shared/libs/scrollTo";
import {validSymbolsNumber} from "@/shared/libs/validation";

export default {
  name: "BaseInput",
  components: {BaseButtonCross},
  props:{
    model:{
      type: String,
      require: false
    },
    postfix:{
      typeNotes:{
        type: String,
        require: false
      },
    },
    label:{
      typeNotes:{
        type: String,
        require: false
      },
    },
    placeholder:{
      typeNotes:{
        type: String,
        require: false
      },
    },
    typeNotes:{
      type: String,
      require: false
    },
    setInputValue:{
      type: String,
      require: false,
      default(){
        return ''
      }
    },
    notCross:{
      type: Boolean,
      require: false,
      default(){
        return false
      }
    },
    focus:{
      type: Boolean,
      require: false,
      default(){
        return false
      }
    }
  },
  emits:['eventBlurInput', 'changeInputValue'],
  computed:{
    colorNotes(){
      if(this.typeNotes){
        return this.typeNotes
      } else {
        return 'default'
      }
    },
    isNotEmptyField(){
      return this.inputValue !== null && this.inputValue.length !== 0
    },
  },
  data(){
    return{
      inputValue: this.setInputValue,
      isFocusField: false
    }
  },
  watch:{
    setInputValue(){
      this.inputValue = this.setInputValue
    },
    focus(){
      this.commandFocusInput()
    }
  },
  methods:{
    // разделение нужно, чтобы послать команду из родителя и не было дублирования фокуса
    commandFocusInput: function(){
      this.$refs.input.focus()
      this.isFocusField = true // транслирует фокус с поля на весь контейнер
    },
    commandBlurInput: function(){
      this.$refs.input.blur()
      this.isFocusField = false // транслирует блюр с поля на весь контейнер
    },

    // Обрабатывает фокус на <input/>
    focusInput: function (el){
      if (el != null){
        useScrollToInput(el)
      }
      this.isFocusField = true // транслирует фокус с поля на весь контейнер
    },

    // Эмитит событие blur <input/>
    // нужен для оповещения в селекторах, если просто ввели текст, а значение не выбрали
    blurInput: function (){
      // для накладывания клавиатуры на поля в MI
      // document.body.classList.remove('paddingForGboard')
      this.isFocusField = false // убирает фокус со всего контейнера
      this.$emit('eventBlurInput')
    },

    // Корректно вставляет значение из буфера обмена
    pasteInputValue: function (e){
      let clipboardData
      let pastedData;
      clipboardData = e.clipboardData || window.clipboardData;
      pastedData = clipboardData.getData('Text');

      this.inputValue = pastedData
      this.changeInputValue()
    },

    // Эмитит значение <input/>
    changeInputValue: function(){
      const value = this.inputValue !== null ? this.inputValue.trim() : null
      this.$emit('changeInputValue', this.validatedValue(value, this.model))
    },

    // Очищает значение в <input/>
    handleEventClean: function (){
      this.inputValue = ''
      this.changeInputValue()
    },

    validatedValue: function(value, model){
      if(value !== '' && (model === 'float')){
        // если модель вес или цена
        const mutationValue = this.inputValue = validSymbolsNumber(value)
        return Number(mutationValue).toFixed(2)
      } else {
        // если текст
        return value
      }
    },
  }
}
</script>

<style>
.base-input__container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background: #FFFFFF;
  border: 1px solid #E6E6E6;
  border-radius: 12px;
  height: 48px;
  padding: 0 15px;
  width: 100%;
  transition: 200ms;
}

.base-input__container:hover{
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.03);
}

.base-input__container--err{
  border-color: #E41F2C;
}

.base-input__container--active{
  border-color: #A1A1A1;
}

.base-input__field{
  font-family: 'Montserrat';
  scroll-margin-top: 40px;
  -webkit-appearance: none;
  height: 100%;
  border: 0;
  outline: none;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #141414;
  background-color: inherit;
  flex: 1;
  padding-right: 15px;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset;
}


.base-input__field:focus{
  background: inherit;
  outline: none;
  border: 0;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'],
input[type="number"]:hover,
input[type="number"]:focus {
  appearance: none;
  -moz-appearance: textfield;
}

.base-input__label{
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #141414;
  margin-right: 5px;
  pointer-events: none;
}

.base-input__postfix{
  font-size: 14px;
  line-height: 130%;
  text-align: right;
  color: #141414;
  margin-left: 10px;
  display: inline-block;
  pointer-events: none;
}

.base-input__notes{
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  margin-top: 4px;
}

.base-input__notes--default{
  color: #141414;
}

.base-input__container--error{
  border-color: #E41F2C;
}
.base-input__notes--error{
  color: #E41F2C;
}

.base-input__notes--attention{
  color: #FF9900;
}
</style>