<template>
  <div class="base-logo"></div>
</template>

<script>
export default {
  name: "BaseLogo"
}
</script>

<style>
.base-logo{
  width: 115px;
  height: 50px;

  background: url("../assets/logo/logo.svg") no-repeat center / contain;
}
</style>