export class Cargo {
    constructor(
        departure, destination, weight, volume, nature,
        uid = null, ip = null, location = null,
        name, email, tel,
        total, delivery_min, delivery_max
    ){
        this.departure = departure.id
        this.destination = destination.id
        this.weight = weight
        this.volume = volume
        this.cargo_type = nature.id
        this.client_uid = uid
        this.client_ip = ip
        this.client_location = location
        this.client = name
        this.email = email
        this.mobile = tel
        this.total = total
        this.delivery_min = delivery_min
        this.delivery_max = delivery_max
    }
}