<template>
  <ul class="list-contact">
    <li class="list-contact__item" v-for="(contact, index) in contacts" :key="index">
      <call-by-phone
          :tel="contact.tel"
          :country="contact.country"
      />
    </li>
  </ul>
</template>

<script>
import CallByPhone from "@/features/call-by-phone/CallByPhone";
export default {
  name: "ListContacts",
  components: {CallByPhone},
  data(){
    return{
      contacts:[
        {
          country: 'ru',
          tel: '+79039048890'
        },
        {
          country: 'kg',
          tel: '+996504113311'
        }
      ]
    }
  }
}
</script>

<style>
.list-contact{
  display: flex;
  align-items: center;
  list-style: none;
}

.list-contact__item{
  margin-left: 30px;
}
</style>