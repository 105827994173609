<template>
  <base-form @submit.prevent>

    <template v-slot:title>
      <div class="base-form__back">
        <base-button-back @click="handleClickBack"/>
      </div>
      <h2 class="base-h2">{{ route }}</h2>
    </template>

    <template v-slot:content>

      <div class="base-form__h3">
        <h3 class="base-h3">{{ vocabulary.h_cargo_params || 'Параметры груза' }}</h3>
      </div>

      <ul class="list-param">
        <li class="item-param" v-for="(param, index) in params" :key="index" >
          <edit-param :param-name="param.value" @editParam="handleEditParam(param.type)"/>
        </li>
      </ul>

      <div class="base-form__container-result">
        <sausage-cargo/>
      </div>

      <div class="base-form__container-button">
        <base-button @click="handleClickToOrder">{{ vocabulary.button_order || 'Оформить заявку' }}</base-button>
      </div>
    </template>
  </base-form>
</template>

<script>
import BaseForm from "@/shared/ui/BaseForm";
import BaseButton from "@/shared/ui/BaseButton";
import BaseButtonBack from "@/shared/ui/BaseButtonBack";
import {mapGetters, mapMutations} from "vuex";
import EditParam from "@/entities/cargo/EditParam";
import SausageCargo from "@/entities/cargo/SausageCargo";
export default {
  name: "AcceptCalculate",
  components: {SausageCargo, EditParam, BaseButtonBack, BaseButton, BaseForm},
  computed:{
    ...mapGetters([
        'getVocabulary',
        'getDeparture',
        'getDestination',
        'getWeight',
        'getVolume',
        'getNature',
        'getCargo',
        'statusLoadingCargo',
        'getCurrentLang'
    ]),
    keyNameByLang(){
      let lang = this.getCurrentLang
      if(lang !== 'ru'){
        lang = 'lat'
      }
      return `name_` + lang
    },
    keyNatureByLang(){
      let lang = this.getCurrentLang
      if(lang !== 'ru' && lang !== 'cn'){
        lang = 'en'
      }
      return `name_` + lang
    },
    vocabulary(){
      return this.getVocabulary
    },
    route(){
      return this.getDeparture[this.keyNameByLang] + ' – ' + this.getDestination[this.keyNameByLang]
    },
    valueWeight(){
      return this.getWeight
    },
    postfixWeight(){
      return this.getVocabulary.postfix_weight
    },
    postfixVolume(){
      return this.getVocabulary.postfix_volume
    },
    valueVolume(){
      return this.getVolume
    },
    valueNature(){
      return this.getNature[this.keyNatureByLang]
    },
    cargo(){
      return this.getCargo
    },
    params(){
      return [
        {
          type: 'nature',
          value: this.valueNature
        },
        {
          type: 'weight',
          value: this.valueWeight + ' ' + this.postfixWeight
        },
        {
          type: 'volume',
          value: this.valueVolume + ' ' + this.postfixVolume
        },
      ]
    }
  },
  methods:{
    ...mapMutations([
        'updatePathOrder',
        'updatePathStart',
        'commandFocusWeight',
        'commandFocusVolume',
        'commandFocusNature'
    ]),
    handleClickBack: function (){
      this.updatePathStart(true)
    },
    handleClickToOrder: function (){
      this.updatePathOrder(true)
    },
    handleEditParam: function (str){
      this.updatePathStart(true)
      if(str === 'weight'){
        this.commandFocusWeight(true)
      } else if(str === 'volume'){
        this.commandFocusVolume(true)
      } else if(str === 'nature'){
        this.commandFocusNature(true)
      }
    }
  }
}
</script>

<style>
.list-param{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  margin-top: -10px;
}
.item-param{
  margin: 10px 10px 0 0;
}
</style>