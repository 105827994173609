<template>
  <div
      class="base-flag-of-the-country"
      :class="'base-flag-of-the-country--' + country"
  ></div>
</template>

<script>
export default {
  name: "BaseFlagOfTheCountry",
  props:{
    country:{
      type: String,
      validator(value){
        return ['ru', 'en', 'cn', 'kg'].includes(value)
      }
    }
  },
}
</script>

<style>
.base-flag-of-the-country{
  width: 20px;
  height: 20px;

  background: no-repeat center / cover;
  border-radius: 10px;
}

.base-flag-of-the-country--ru{
  background-image: url('../assets/flags/ru.jpg')
}
.base-flag-of-the-country--en{
  background-image: url('../assets/flags/en.jpg')
}
.base-flag-of-the-country--cn{
  background-image: url('../assets/flags/cn.jpg')
}
.base-flag-of-the-country--kg{
  background-image: url('../assets/flags/kg.jpg')
}

</style>