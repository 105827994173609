export default {
    mutations: {
        updateLoadingCargo(state, flag){
            state.loadingCargo = flag
        },
        updateErrorCargo(state, str){
            state.errorCargo = str
        },
        updateCalculateCargo(state, obj){
            state.cargo = obj
        },
        updateDeparture(state, obj){
            state.departure = obj
        },
        updateDestination(state, obj){
            state.destination = obj
        },
        updateWeight(state, str){
            state.weight = str
        },
        updateVolume(state, str){
            state.volume = str
        },
        updateNature(state, obj){
            state.nature = obj
        },
        commandFocusWeight(state, flag){
            state.focusWeight = flag
        },
        commandFocusVolume(state, flag){
            state.focusVolume = flag
        },
        commandFocusNature(state, flag){
            state.focusNature = flag
        }
    },
    state: {
        departure: null,
        destination: null,
        nature: null,
        volume: null,
        weight: null,
        focusWeight: false,
        focusVolume: false,
        focusNature: false,
        cargo: null,
        loadingCargo: false,
        errorCargo: ''
    },
    getters: {
        getCargo(state){
            return state.cargo
        },
        getCargoError(state){
            return state.errorCargo
        },
        getDeparture(state){
            return state.departure
        },
        getDestination(state){
            return state.destination
        },
        getWeight(state){
            return state.weight
        },
        getVolume(state){
            return state.volume
        },
        getNature(state){
            return state.nature
        },
        statusFocusWeight(state){
            return state.focusWeight
        },
        statusFocusVolume(state){
            return state.focusVolume
        },
        statusFocusNature(state){
            return state.focusNature
        },
        statusLoadingCargo(state){
            return state.loadingCargo
        },
    }
}