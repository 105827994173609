<template>
<div class="main-header">
  <div class="wrap">
    <base-logo/>
    <div class="main-header__navigation">
      <widget-contacts/>
      <selector-lang/>
    </div>
  </div>
</div>
</template>

<script>
import BaseLogo from "@/shared/ui/BaseLogo";
import SelectorLang from "@/widgets/lang/SelectorLang";
import WidgetContacts from "@/widgets/contact/WidgetContacts";
export default {
  name: "MainHeader",
  components: {WidgetContacts, SelectorLang, BaseLogo},
}
</script>

<style>
.main-header{
  padding: 20px;
}

.main-header .wrap{
  display: flex;
  justify-content: space-between;
  /*align-items: flex-end;*/
}

.main-header__navigation{
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.main-header__navigation > * {
  margin-left: 25px
}

@media screen and (min-width: 768px){
  .main-header__navigation .base-dropdown__headline,
  .main-header__navigation .base-dropdown{
    height: 100%;
  }

  .main-header__navigation .base-dropdown__headline{
    display: flex;
    align-items: flex-end;
  }
}
</style>