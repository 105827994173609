<template>
  <base-input
      :label="vocabulary.label_name + ':'"
      @changeInputValue="handleValueName"
      @eventBlurInput="handleEventBlurInput"
  />
</template>

<script>
import BaseInput from "@/shared/ui/BaseInput";
import {mapGetters} from "vuex";
export default {
  name: "InputName",
  components: {BaseInput},
  emits:['valueName'],
  computed:{
    ...mapGetters(['getVocabulary']),
    vocabulary(){
      return this.getVocabulary
    }
  },
  methods:{
    handleValueName: function (str){
      this.$emit('valueName', str)
    },
    handleEventBlurInput: function (){}
  }
}
</script>