<template>
  <form class="form" @submit.prevent>
    <div class="form__container-input">
      <selector-departure @selectedDeparture="handleSelectedDeparture"/>
    </div>
    <div class="form__container-input">
      <selector-destination @selectedDestination="handleSelectedDestination"/>
    </div>
    <div class="form__container-input">
      <selector-nature @selectedNature="handleSelectedNature"/>
    </div>
    <div class="form__container-flex">
      <input-weight @valueWeight="handleValueWeight"/>
      <input-volume @valueVolume="handleValueVolume"/>
    </div>
    <div class="base-form__container-button">
      <base-button :is-disabled="lockButtonCalculate" @submit.prevent @keyup.enter.prevent @click.prevent="handleClickCalculate"> {{ vocabulary.button_calculate || 'Рассчитать'  }} </base-button>
    </div>
  </form>
</template>

<script>
import SelectorNature from "@/entities/cargo/SelectorNature";
import InputVolume from "@/shared/ui/InputVolume";
import InputWeight from "@/shared/ui/InputWeight";
import BaseButton from "@/shared/ui/BaseButton";
import {mapGetters, mapMutations} from "vuex";
import SelectorDeparture from "@/entities/cargo/SelectorDeparture";
import SelectorDestination from "@/entities/cargo/SelectorDestination";
import {getCalculateCargo} from "@/features/calculate-cargo/api/postCalculate";
export default {
  name: "CalculateCargo",
  components: {
    SelectorDestination,
    SelectorDeparture, BaseButton, InputWeight, InputVolume, SelectorNature},
  data(){
    return{
      lockButtonCalculate: true, // статус кнопки "Рассчитать"
      departure: null,
      destination: null,
      volume: null,
      weight: null,
      nature: null
    }
  },
  beforeMount() {
    this.departure = this.pointDeparture
    this.destination = this.pointDestination
    this.volume = this.valueVolume
    this.weight = this.valueWeight
    this.nature = this.valueNature
  },
  computed:{
    ...mapGetters(['getVocabulary', 'getDeparture', 'getDestination', 'getNature', 'getWeight', 'getVolume', 'getUID']),
    vocabulary(){
      return this.getVocabulary
    },
    pointDeparture(){
      return this.getDeparture
    },
    pointDestination(){
      return this.getDestination
    },
    valueWeight(){
      return this.getWeight
    },
    valueVolume(){
      return this.getVolume
    },
    valueNature(){
      return this.getNature
    },
    uid(){
      return this.getUID
    },
  },
  watch:{
    departure(){
      this.handleFullnessFormTariff()
    },
    destination(){
      this.handleFullnessFormTariff()
    },
    nature(){
      this.handleFullnessFormTariff()
    },
    volume(){
      this.handleFullnessFormTariff()
    },
    weight(){
      this.handleFullnessFormTariff()
    }
  },
  methods:{
    ...mapMutations([
        'updatePathResult',
        'updateDeparture',
        'updateDestination',
        'updateWeight',
        'updateNature',
        'updateVolume',
        'updateCalculateCargo',
        'updateLoadingCargo',
        'updateErrorCargo',
    ]),

    handleSelectedDeparture: function (obj){
      this.updateDeparture(obj)
      this.departure = this.pointDeparture
    },
    handleSelectedDestination: function (obj){
      this.updateDestination(obj)
      this.destination = this.pointDestination
    },
    handleSelectedNature: function (obj){
      this.updateNature(obj)
      this.nature = this.valueNature
    },
    handleValueWeight: function (num){
      this.updateWeight(Number(num))
      this.weight = this.valueWeight
    },
    handleValueVolume: function (num){
      this.updateVolume(Number(num))
      this.volume = this.valueVolume
    },

    // Обрабатывает заполнение формы
    handleFullnessFormTariff: function (){
      if(this.departure != null && this.destination != null && this.volume != null && this.volume !== 0 && this.weight != null && this.weight !== 0 && this.nature != null){
        // форма заполнена полностью
        this.addLocalStorage() // записывает параметры расчета в local storage
        this.lockButtonCalculate = false // разблокирует кнопку "Рассчитать"
      } else if (this.departure == null && this.destination == null && this.volume == null && this.volume === 0 && this.weight == null && this.weight === 0 && this.nature != null){
        // форма очищена
        this.lockButtonCalculate = true // блокирует кнопку "Рассчитать"
        this.cleanLocalStorage() // очистка local storage
      } else {
        // форма заполнена не полностью
        this.lockButtonCalculate = true // блокирует кнопку "Рассчитать"
      }
    },

    // Отправляет запрос на расчет
    handleClickCalculate: async function (){
      await this.updateLoadingCargo(true)// начало загрузки
      this.updatePathResult(true) // включение окна результатов
      // обнуление статуса заполненых полей
      // выключение окна ошибок
      const response = await getCalculateCargo(
          this.departure,
          this.destination,
          this.weight,
          this.volume,
          this.nature,
          this.uid, '-', '-'
      ) // fetch
      this.updateErrorCargo(response.error)// записываем статус ошибки number / null
      await this.updateCalculateCargo(response.cargo) // записываем расчет {} / null
      this.updateLoadingCargo(false) // загрузка закончена
    },

    // Записывает в local storage
    addLocalStorage: function (){
      const params = {
        departure: this.departure,
        destination: this.destination,
        estimated: this.estimated,
        weight: this.weight,
        nature: this.nature,
      };
      localStorage.setItem('storageCalculate', JSON.stringify(params))
    },

    // Очищает local storage
    cleanLocalStorage: function (){
      localStorage.removeItem('storageCalculate')
    }
  }
}
</script>

<style>
.form__container-flex > *,
.form__container-input{
  margin-bottom: 10px;
}

@media screen and (min-width: 768px){
  .form__container-flex{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .form__container-flex > *{
    width: 50%;
    margin-bottom: 0;
  }

  .form__container-flex >*:first-child{
    margin-right: 10px;
  }
}

</style>