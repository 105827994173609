<template>
  <base-dropdown :command-visible="commandVisibleDropdown">
    <!-- input -->
    <template v-slot:head>
      <base-input
          ref="inputDesktop"
          :label="typePoint"
          :not-cross="isMobile"
          :set-input-value="selectedPoint != null ? selectedPoint[keyNameByLang] : ''"
          @changeInputValue="handleChangeInputValue"
          @eventBlurInput="handleBlurInputValue"
          @click="handleClickInput($el)"
      />
    </template>

    <!-- заголовок в шторке -->
    <template v-slot:headline>
      <div class="base-driver__title">
        <div class="selector-point__container-input">
          <!-- строка поиска в шторке -->
          <base-input
              ref="inputMobile"
              placeholder="Поиск"
              :set-input-value="selectedPoint != null ? selectedPoint[keyNameByLang] : ''"
              @changeInputValue="handleChangeInputValue"
          />
          <!-- отмена в шторке -->
          <base-cancel @click.prevent="handleClickCancel"/>
        </div>
      </div>
    </template>

    <!-- контент -->
    <template v-slot:body>
      <div class="selector-point__undefined" v-if="isListDefault">
        <div class="base-description" style="text-align: left"> {{ vocabulary.not_found || 'По запросу ничего не найдено' }} </div>
        <div class="container-default-list__title">
          <p>{{ vocabulary.access_cities || 'Доступные города' }}</p>
        </div>
      </div>
      <base-list>
        <template v-if="isLoading">
          <li class="base-list__item">
            <span class="skeleton__title"></span>
          </li>
          <li class="base-list__item">
            <span class="skeleton__title"></span>
          </li>
          <li class="base-list__item">
            <span class="skeleton__title"></span>
          </li>
        </template>
        <li
            v-else
            class="base-list__item"
            v-for="(itemPoint, index) in list"
            :key="index"
            @click="handleClickPoint(itemPoint)"
        >
          {{ itemPoint[keyNameByLang] }}
        </li>
      </base-list>
    </template>
  </base-dropdown>
</template>

<script>
import BaseDropdown from "@/shared/ui/BaseDropdown";
import BaseInput from "@/shared/ui/BaseInput";
import BaseCancel from "@/shared/ui/BaseCancel";
import BaseList from "@/shared/ui/BaseList";
import {handleWindowResize} from "@/shared/libs/windowListener";
import {validMinSymbolsForSelectSearch} from "@/shared/libs/validation";
import {mapGetters} from "vuex";
import {getDefaultListDeparture} from "@/entities/cargo/api/getListDeparture";

export default {
  name: "SelectorPoint",
  props:{
    typePoint:{
      type: String
    },
    funcGetList:{
      type: Function,
      require: false
    },
    listPoints:{
      type: Object,
      require: false,
      default(){
        return null
      }
    },
    isNowShowList:{
      type: Boolean,
      default(){
        return false
      }
    },
    setSelectedPoint:{
      type: Object,
      require: false
    }
  },
  emits:['selectedPoint'],
  components: {BaseList, BaseCancel, BaseInput, BaseDropdown},
  data(){
    return{
      list: this.listPoints,
      isListDefault: false,
      selectedPoint: null,
      search: '',
      commandVisibleDropdown: false,
      isLoading: false
    }
  },
  computed:{
    ...mapGetters(['getCurrentLang', 'getVocabulary']),
    keyNameByLang(){
      let lang = this.getCurrentLang
      if(lang !== 'ru'){
        lang = 'lat'
      }
      return `name_` + lang
    },

    vocabulary(){
      return this.getVocabulary
    },

    isMobile(){
      return handleWindowResize() < 768
    },
    currentLang(){
      return this.getCurrentLang
    }
  },
  beforeMount() {
    this.selectedPoint = this.setSelectedPoint
  },
  watch:{
    selectedPoint(){
      this.$emit('selectedPoint', this.selectedPoint)
    }
  },
  methods:{
    handleClickPoint: function (obj){
      this.selectedPoint = obj // присваивает выбранного поинт
      this.commandVisibleDropdown = false // закрывает дропдаун
    },

    // Отслеживает blur в input
    handleBlurInputValue: function (){
      if(this.selectedPoint){
        if(this.search !== this.selectedPoint[this.keyNameByLang]){
          this.selectedPoint = null // обнуление выбранного города
          this.search = ''
        }
      }
    },

    // Отслеживает изменения в инпуте
    handleChangeInputValue: function (str){
      this.search = str !== null ? validMinSymbolsForSelectSearch(str.toLowerCase(), 2) : null // записываем значение если больше 2х символов
      if(this.isNowShowList && this.search == null){
        // не обнуляем лист если до 2х символов и нужно показывать доступные города пунктов отправления сразу
        this.getDefaultListDeparture()
      } else {
        // обнуляем если больше 2х символов
        this.list = null
        this.isListDefault = false
        this.handleEventInput() // запускаем изменение события
      }
    },

    handleEventInput: function(){
      if(!this.isMobile){
        // десктоп: работа со всплываемым дропдауном
        if(this.search != null){
          // значение от 2х символов
          this.commandVisibleDropdown = true // открывает дропдаун
          this.getListPoints() // запрашивает список на получение
        } else {
          this.selectedPoint = null // обнуляем город
          this.commandVisibleDropdown = false // закрывает дропдаун
        }
      } else {
        // мобилка: работа со отдельным шаблоном дропдауна
        if(this.search != null){
          // значение от 2х символов
          this.getListPoints() // запрашивает список на получение
        } else {
          this.selectedPoint = null // обнуляем город
        }
      }
    },

    // Получает список городов по поисковому запросу через api
    getListPoints: async function(){
      this.isLoading = true // включаем скелетон
      const points = await this.funcGetList(this.currentLang, this.search) // запрашивает список по переданной функции в props
      this.isLoading = false // выключаем скелетон
      this.list = points.listSort // перезаписывает лист городов
      this.isListDefault = points.listDefault // перезаписывает дефолтный лист городов
    },

    // Получает дефолтный список городов отправления через api
    getDefaultListDeparture: async function(){
      this.isLoading = true // включаем скелетон
      const points = await getDefaultListDeparture(this.currentLang) // запрашивает список по переданной функции в props
      this.isLoading = false // выключаем скелетон
      this.list = points.listSort // перезаписывает лист городов
      this.isListDefault = false // перезаписывает дефолтный лист городов
    },

    handleClickInput: function (){
      if (this.isMobile){
        this.$refs.inputDesktop.commandBlurInput()
        this.commandVisibleDropdown = true // открывает дропдаун
        setTimeout(() => {
          this.$refs.inputMobile.commandFocusInput()
        }, 1); // сначала показываем дропдаун, потом определяем вокус
      }

      if(this.isNowShowList){
        // нужно показывать доступные города пунктов отправления сразу
        this.getDefaultListDeparture() // идем сразу в дефолтный список
        this.commandVisibleDropdown = true // открывает дропдаун
      }
    },

    // Обрабатывае клик по base-cancel
    handleClickCancel: function (){
      this.selectedPoint = null // обнуляем город
      this.commandVisibleDropdown = false // закрывает дропдаун
    }
  }
}
</script>

<style>
.container-default-list__title{
  margin: 20px 0;
  font-size: 14px;
}

.selector-point__container-input{
  display: flex;
  align-items: center;
}
.selector-point__container-input .base-input{
  flex: 1;
  margin-right: 10px;
}

@media screen and (min-width: 768px){
  .selector-point__undefined{
    padding: 0 16px;
    padding-top: 15px;
  }
}
</style>

<style scoped>
.base-list__item{
  padding: 15px;
}
</style>