export const validMinSymbolsForSelectSearch = (str, counter = 2) =>{
    return str.length > counter ? str : null
}
export const validSymbolsNumber = value =>{
    return value.replace(/[^\d,.]*/g, '')
        .replace(/([,.])[,.]+/g, '$1')
        .replace(/^[^\d]*(\d+([.,]\d{0,5})?).*$/g, '$1')
        .replace(/,/, '.')
}
export const validMaxSymbolsNumber = value =>{
    return (value < 999999) ? value : null
}
export const validEmail = str =>{
    const re = /[a-z0-9!#$%&'*+=?^._`{|}~-]*@[a-z0-9]*\.[a-z0-9]{2,}/
    return re.test(String(str).toLowerCase());
}

export const isStatusSuccess = num => {
    return num >= 200 && num <= 300
}
export const normalizeStatus = num => {
    if(num >= 500 && num <= 600){
        return 500
    } else {
        return num
    }

}