<template>
  <component :is="typeSizeWindowListContacts"/>
</template>

<script>
import {handleWindowResize} from "@/shared/libs/windowListener";
import ListContacts from "@/widgets/contact/ui/ListContacts";
import ListContactsMobile from "@/widgets/contact/ui/ListContactsMobile";

export default {
  name: "WidgetContacts",
  components: {ListContactsMobile, ListContacts},
  computed:{
    isMobile(){
      return handleWindowResize() < 768
    },
    typeSizeWindowListContacts(){
      return this.isMobile ? `ListContactsMobile` : `ListContacts`
    }
  },
}
</script>