<template>
  <base-dropdown :command-visible="commandVisibleDropdown">
    <!-- input -->
    <template v-slot:head>
      <a class="selector-contact__head" @click="handleClickLink"> {{ vocabulary.button_call }} </a>
    </template>
    <!-- крестик в шторке -->
    <template v-slot:cross>
      <base-button-cross @click="handleClickClose"/>
    </template>

    <!-- заголовок в шторке -->
    <template v-slot:headline>
      <div class="base-driver__title">
        <h2 class="base-h2" style="text-align: left"> {{ vocabulary.button_call }} </h2>
      </div>
    </template>

    <!-- контент -->
    <template v-slot:body>
      <base-list>
        <li
            class="base-list__item"
            v-for="(contact, index) in contacts" :key="index">
          <call-by-phone
              :tel="contact.tel"
              :country="contact.country"
          />
        </li>
      </base-list>
    </template>
  </base-dropdown>
</template>

<script>
import BaseDropdown from "@/shared/ui/BaseDropdown";
import BaseButtonCross from "@/shared/ui/BaseButtonСross";
import BaseList from "@/shared/ui/BaseList";
import CallByPhone from "@/features/call-by-phone/CallByPhone";
import {mapGetters} from "vuex";
export default {
  name: "ListContactsMobile",
  components: {CallByPhone, BaseList, BaseButtonCross, BaseDropdown},
  data(){
    return{
      commandVisibleDropdown: false,
      contacts:[
        {
          country: 'ru',
          tel: '+79039048890'
        },
        {
          country: 'kg',
          tel: '+996504113311'
        }
      ]
    }
  },
  computed:{
    ...mapGetters(['getVocabulary']),
    vocabulary(){
      return this.getVocabulary
    }
  },
  methods:{
    // Обрабатывает событие click из base-list
    handleClickItem: function (){
      this.commandVisibleDropdown = false // закрывает base-dropdown
    },

    handleClickLink: function (){
      this.commandVisibleDropdown = true // открывает дропдаун
    },
    handleClickClose: function (){
      this.commandVisibleDropdown = false // открывает дропдаун
    }
  }
}
</script>

<style>
.selector-contact__head{
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #EE1B2C;
  cursor: pointer;
}
</style>