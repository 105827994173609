<template>
  <base-input
      :label="vocabulary.label_mobile + ':' || 'Телефон'"
      @changeInputValue="handleValueTel"
      @eventBlurInput="handleEventBlurInput"
      :type-notes="notes != null ? notes.type : null"
  > {{ notes != null ? notes.msg : '' }} </base-input>
</template>

<script>
import BaseInput from "@/shared/ui/BaseInput";
import {mapGetters} from "vuex";
export default {
  name: "InputTel",
  components: {BaseInput},
  data(){
    return{
      notes: null
    }
  },
  computed:{
    ...mapGetters(['getVocabulary']),
    vocabulary(){
      return this.getVocabulary
    }
  },
  methods:{
    handleValueTel: function (str){
      const tel = str
      if(tel.length !== 0){
        this.notes = null
        this.$emit('valueTel', tel)
      } else {
        this.notes = {
          type: 'error',
          msg: this.vocabulary.incorrect_value
        }
        this.$emit('valueTel', null)
      }

    },
    handleEventBlurInput: function (){}
  }
}
</script>

<style scoped>

</style>