import {vue3Debounce} from 'vue-debounce'
import {createApp} from 'vue'
import {store} from "@/store";
import App from './App.vue'


const app = createApp(App)
app
    .use(store)
    .directive('debounce', vue3Debounce({ lock: true }))
    .mount('#app');
