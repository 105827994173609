<template>
  <div class="page-success">
    <div class="page-success__title">
      <h2 class="base-h2"> {{ vocabulary.h_success || 'Оформление заявки' }} </h2>
    </div>
    <div class="page-success__description">
      <p class="base-description">{{ vocabulary.p_success || 'Оставьте свои контакты, и наш менеджер свяжется с вами' }}</p>
    </div>
    <div class="page-success__button">
      <base-button
          :is-secondary-button="true"
          @click="handleClickBackHome"
      >
        {{ vocabulary.button_home || 'Вернуться на главную' }}
      </base-button>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/shared/ui/BaseButton";
import {mapGetters, mapMutations} from "vuex";
export default {
  name: "WidgetSuccess",
  components: {BaseButton},
  computed:{
    ...mapGetters(['getVocabulary']),
    vocabulary(){
      return this.getVocabulary
    }
  },
  methods:{
    ...mapMutations(['updatePathStart']),
    handleClickBackHome: function (){
      this.updatePathStart(true)
    }
  }
}
</script>

<style>
.page-success{
  max-width: 345px;
  margin: 0 auto;
  padding-top: 100px;
}

.page-success__title{
  margin-bottom: 10px;
}
.page-success__button{
  margin-top: 30px;
}
</style>