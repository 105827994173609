<template>
  <base-dropdown :command-visible="commandVisibleDropdown">
    <!-- input -->
    <template v-slot:head>
      <base-select
          ref="nature"
          :set-select-value="selectedItemNature[keyNameByLang] != null ? selectedItemNature[keyNameByLang] : getLabelNature"
          @click="handleClickSelect"/>
    </template>
    <!-- крестик в шторке -->
    <template v-slot:cross><base-button-cross @eventClean="handleClickClose"/></template>
    <!-- заголовок в шторке -->
    <template v-slot:headline>

      <div class="base-driver__title">
        <h2 class="base-h2" style="text-align: left">{{ getLabelNature }}</h2>
      </div>
    </template>

    <!-- контент -->
    <template v-slot:body>
      <base-list>
        <template v-if="isLoading">
          <li class="base-list__item base-list__item--pd">
            <span class="skeleton__title"></span>
          </li>
          <li class="base-list__item base-list__item--pd">
            <span class="skeleton__title"></span>
          </li>
          <li class="base-list__item base-list__item--pd">
            <span class="skeleton__title"></span>
          </li>
        </template>
        <li
            class="base-list__item"
            v-for="(itemNature, index) in listNature"
            :key="index"
        >
          <select-nature
              :is-picked="itemNature[keyNameByLang] === selectedItemNature[keyNameByLang]"
              :nature="itemNature"
              @selectNature="handleClickItem(itemNature)"
          />
        </li>
      </base-list>
    </template>
  </base-dropdown>
</template>

<script>
import BaseDropdown from "@/shared/ui/BaseDropdown";
import BaseSelect from "@/shared/ui/BaseSelect";
import BaseList from "@/shared/ui/BaseList";
import BaseButtonCross from "@/shared/ui/BaseButtonСross";
import {mapGetters, mapMutations} from "vuex";
import SelectNature from "@/entities/cargo/ui/SelectNature";
import {getListNature} from "@/entities/cargo/api/getListNature";
export default {
  name: "SelectorNature",
  components: {SelectNature, BaseButtonCross, BaseList, BaseSelect, BaseDropdown},
  data(){
    return{
      listNature: null,
      commandVisibleDropdown: false,
      selectedItemNature: {},
      isLoading: false
    }
  },
  computed:{
    ...mapGetters(['statusFocusNature', 'getVocabulary', 'getCurrentLang', 'getNature']),
    keyNameByLang(){
      let lang = this.getCurrentLang
      if(lang !== 'ru' && lang !== 'cn'){
        lang = 'en'
      }
      return `name_` + lang
    },
    isFocus(){
      return this.statusFocusNature
    },
    vocabulary(){
      return this.getVocabulary
    },
    currentLang(){
      return this.getCurrentLang
    },
    getValueNature(){
      return this.getNature
    },
    getLabelNature(){
      return this.getVocabulary.label_cargo_type
    }
  },
  watch:{
    selectedItemNature(){
      this.$emit('selectedNature', this.selectedItemNature)
    }
  },
  methods:{
    ...mapMutations(['commandFocusNature']),
    // Обрабатывает событие click из base-button-cross
    handleClickClose: function (){
      this.commandFocusNature(false)
      this.commandVisibleDropdown = false // закрывает base-dropdown
    },

    // Обрабатывает событие click из base-list
    handleClickItem: function (obj){
      this.selectedItemNature = obj // записываем выбранное значение
      this.commandVisibleDropdown = false // закрывает base-dropdown
      this.commandFocusNature(false)
    },

    // Обрабатывает событие click из base-select
    handleClickSelect: async function(){
      this.isLoading = true // включаем скелетон
      this.commandVisibleDropdown = true // открывает base-dropdown
      this.listNature = await getListNature(this.currentLang) // запрашивает список характера груза
      this.isLoading = false // выключаем скелетон
    },

  },
  mounted() {
    if(this.getValueNature != null){
      this.selectedItemNature = this.getValueNature
    }

    if(this.isFocus){
      setTimeout(() => {
        this.handleClickSelect()
      }, 1)
    }
  },
}
</script>
