<template>
  <accept-calculate/>
</template>

<script>
import AcceptCalculate from "@/features/accept-calculate/AcceptCalculate";
export default {
  name: "WidgetCalculateResult",
  components: {AcceptCalculate},
}
</script>