<template>
    <span class="card-lang__flag">
        <base-flag-of-the-country :country="lang"/>
    </span>
    <span class="card-lang__content">{{ lang }}</span>
</template>

<script>
import BaseFlagOfTheCountry from "@/shared/ui/BaseFlagOfTheCountry";
export default {
  name: "CardLang",
  components: {BaseFlagOfTheCountry},
  props: {
    lang: {
      type: String,
      validator(value) {
        return ['ru', 'en', 'cn'].includes(value)
      },
      require: true
    },
  }
}
</script>

<style>


.card-lang__flag{
  margin-right: 10px;
}

.card-lang__content{
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
  flex: 1;
  text-transform: capitalize;
}
</style>