<template>
  <button class="base-cancel">Отмена</button>
</template>

<script>
export default {
  name: "BaseCancel"
}
</script>

<style>
.base-cancel{
  font-family: Montserrat;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  text-decoration: none;
  color: #1B23EE;
  cursor: pointer;
  border: 0;
  background: transparent;
}
</style>