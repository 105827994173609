<template>
  <button class="button-back">
    <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.21659 15.6834L0.22811 8.59103C0.145161 8.5066 0.0865436 8.41513 0.0522578 8.31662C0.0174191 8.21812 0 8.11258 0 8C0 7.88742 0.0174191 7.78188 0.0522578 7.68338C0.0865436 7.58487 0.145161 7.4934 0.22811 7.40897L7.21659 0.295514C7.41014 0.0985048 7.65207 0 7.9424 0C8.23272 0 8.48157 0.105541 8.68894 0.316623C8.89631 0.527704 9 0.773967 9 1.05541C9 1.33685 8.89631 1.58311 8.68894 1.7942L2.59217 8L8.68894 14.2058C8.88249 14.4028 8.97926 14.6454 8.97926 14.9336C8.97926 15.2224 8.87558 15.4723 8.6682 15.6834C8.46083 15.8945 8.21889 16 7.9424 16C7.6659 16 7.42396 15.8945 7.21659 15.6834Z" fill="#A2A2A2"/>
    </svg>
  </button>
</template>

<script>
export default {
  name: "BaseButtonBack",
}
</script>

<style>
.button-back{
  width: 40px;
  height: 40px;

  top: 64px;
  left: 50%;

  background: #FFFFFF;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);

  border: 0;
  outline: none;

  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.button-back svg{
  position: relative;
  left: -2px;
}
</style>